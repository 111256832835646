import React, { useEffect } from 'react'
import E from 'wangeditor'
import { uploadFile } from "../../api"

let editor = null
function Index(props) {
    const { value, onChange } = props;
    useEffect(() => {
        // 注：class写法需要在componentDidMount 创建编辑器
        editor = new E("#editor")
        editor.config.zIndex = 0
        editor.config.showLinkImg = false
        editor.config.onchange = (newHtml) => {
            onChange(newHtml);
        }

        // 自定义上传图片到后端返回url, 插入到编辑器中
        editor.config.customUploadImg = function (resultFiles, insertImgFn) {
            let allFiles = resultFiles
            let fd;
            Object.keys(allFiles).forEach((key) => {
                fd = new FormData()
                fd.append("file", allFiles[key]);
                fd.append("state", 5);
                // fd.append("category_id", "");
                uploadFile(fd).then(res => {
                    if(res.code === 200){
                        if (res.data && res.data.url) insertImgFn(res.data.url)
                    }else{
                        alert(res.msg)
                    }
                })
            })

        }


        // 需要展示的菜单
        editor.config.menus = [
            'undo',
            'redo',
            'head',
            'bold',
            'fontSize',
            // 'fontName',
            'italic',
            'underline',
            'strikeThrough',
            'indent',
            // 'lineHeight',
            'foreColor',
            'backColor',
            'link',
            'list',
            'justify',
            'quote',
            'table',
            'image',
            // 'splitLine',
        ]

        /**一定要创建 */
        editor.create()
        // editor.txt.html(value);

        return () => {
            // 组件销毁时销毁编辑器 注：class写法需要在componentWillUnmount中调用
            editor.destroy()
        }

        // 这里一定要加上下面的这个注释
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (editor) {
            editor.txt.html(value);
        }
    }, [value])
    return (
        <div>
            <div id="editor"></div>
        </div>
    )
}

export default Index
