import React from 'react'
import { useRoutes, Outlet } from "react-router-dom"
import { myRouters } from "./routes/index"
import ParamsContextProvider from './utils/context';

function App() {
	const elements = useRoutes(myRouters)
	return (
		<ParamsContextProvider>
			<div style={{ height: "100vh" }}>
				{/* <Routes>
				<Route path="/admin/" element={<Admin />} />
				<Route path="/" element={<Login />} ></Route>
				<Route path="*" element={<h1>404 Page Not Found</h1>} ></Route>
			</Routes> */}
				{elements}
				<Outlet />
			</div>
		</ParamsContextProvider>
	);
}

export default App;
