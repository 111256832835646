import React, { useState, useEffect } from 'react'
import { Table, Button, Form, Input, Select, TreeSelect,Pagination, Tag } from "antd"
import { SearchOutlined } from '@ant-design/icons';
import { productList, productCateList } from "../../api"
const { Option } = Select;
function ComRelatePro(props) {
	const [tableData, setTableData] = useState([])
	const [pageInfo, setPageInfo] = useState({ page: 1, page_size: 10, total: 0 })
	const [loading, setLoading] = useState(true)
	const [cateData, setCateData] = useState([])

	const [formQuery] = Form.useForm();
	const onQueryFinish = (values) => {
		if (pageInfo.page === 1) {
			getData()
		} else {
			setPageInfo({ ...pageInfo, page: 1 })
		}
	};

	// 表格模块
	const tableColumns = [
		{
			title: '缩略图',
			dataIndex: 'thumb',
			width: '90px',
			render: (text, record) => {
				return record.thumb && record.thumb.file_path ? <a href={record.thumb.file_path} target="_blank" rel="noreferrer"><img src={record.thumb.file_path} alt="" style={{ width: "50px", height: "50px" }} /></a> : ''
			},
		},
		{
			title: '产品名称',
			dataIndex: 'title',
			key: 'title',
			width: 260
		},
		{
			title: '所属分类',
			dataIndex: 'cate_title',
			key: 'cate_title',
		},
		{
			title: '产品状态',
			dataIndex: 'is_sale',
			width: '105px',
			render: (text, record) => {
				return record.is_sale === 1 ? <Tag color="#108ee9">上架</Tag> : <Tag color="#ff5500">下架</Tag>
			},
		},
		{
			title: '审核状态',
			dataIndex: 'is_verify',
			key: 'is_verify',
			width: 80,
			render: (text, record) => {
				return record.is_verify === 2 ? <Tag color="#2db7f5">已审核</Tag> : <Tag color="#f50">待审核</Tag>
			},
		},
		// {
		// 	title: '排序',
		// 	dataIndex: 'sort',
		// 	key: 'sort',
		// 	width: 80
		// },
	]


	const getData = () => {
		setLoading(true)
		productList({ ...pageInfo, ...formQuery.getFieldsValue() }).then(res => {
			if (res.code === 200) {
				setTableData(res.data.data)
				setPageInfo({ ...pageInfo, total: res.data.total })
				setLoading(false)
			}
		}).catch(err => {
			console.log(err)
			setLoading(false)
		})
	}
	useEffect(() => {
		getData();
	}, [pageInfo.page])

	useEffect(() => {
		productCateList({ type: 2 }).then(res => {
			if (res.code === 200) {
				setCateData(res.data)
			}
		}).catch(err => {
			console.log(err)
		})
	}, [])

	// 分页设置
	const onPageChange = (page) => {
		setPageInfo({ ...pageInfo, page: page })
	}
	const onSelectChange = selectedRowKeys => {
        let _pushData = [];
        tableData.forEach((item,index)=>{
            if(selectedRowKeys.includes(item.id)){
                _pushData.push(item);
            }
        })
        props.handleProductSelected(_pushData);
        // setAllSelectedRowKeys(selectedRowKeys);
    };

	return (
		<div>
			<div className='query-form-box'>
				<Form layout="inline" form={formQuery} onFinish={onQueryFinish}>
					<Form.Item name="cate_id" label="">
						<TreeSelect placeholder='所属分类' allowClear treeData={cateData} style={{ width: '150px' }} treeDefaultExpandAll />
					</Form.Item>
					<Form.Item name="is_sale">
						<Select allowClear placeholder="产品状态" style={{ width: '100px' }}>
							<Option value={1}>上架</Option>
							<Option value={2}>下架</Option>
						</Select>
					</Form.Item>
					<Form.Item name="is_verify">
						<Select allowClear placeholder="审核状态" style={{ width: '100px' }}>
							<Option value={1}>待审核</Option>
							<Option value={2}>已审核</Option>
						</Select>
					</Form.Item>
					<Form.Item name="keyword" label="">
						<Input allowClear placeholder='产品名称' />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit" ghost>
							<SearchOutlined />
							查询
						</Button>
					</Form.Item>
				</Form>
			</div>

			<Table  rowSelection={{ type: 'checkbox', onChange: onSelectChange }} size='small' rowKey='id' columns={tableColumns} dataSource={tableData} pagination={false} loading={loading} />
			<div className="page-box">
				<Pagination current={pageInfo.page} total={pageInfo.total} pageSize={pageInfo.page_size} onChange={onPageChange} />
			</div>
		</div>
	)
}

export default ComRelatePro