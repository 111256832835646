import Login from '../pages/Login';
import Admin from "../pages/layout/Admin"
import Home from "../pages/home/Home";
import Profile from '../pages/Me/Profile';
import User from '../pages/userAuth/User';
import Role from '../pages/userAuth/Role';
import Member from '../pages/member/Member';
import Article from '../pages/article/Article';
import ArticleCate from '../pages/article/ArticleCate';
import ProductCate from '../pages/product/ProductCate';
import Product from '../pages/product/Product';
import Tpl from '../pages/product/Tpl';
import Params from '../pages/product/Params';
import Forms from '../pages/system/Forms';
import Ads from '../pages/system/Ads';
import SinglePage from '../pages/system/SinglePage';
import Logs from '../pages/system/Logs';
import Inquiry from '../pages/member/Inquiry';
import Visit from '../pages/member/Visit';
// import TypeCate from '../pages/product/TypeCate';
import Config from '../pages/system/Config';

export const myRouters = [
	{
		path: '/',
		element: <Login />,
	},
	{
		path: '/admin',
		element: <Admin />,
		children:[
			{
				path: 'home',
				element: <Home />,
			},
			{
				path: 'profile',
				element: <Profile />,
			},
			{
				path: 'article',
				// element: <Default />,
				children:[
					{
						path: 'articleList',
						element: <Article />,
					},
					{
						path: 'articleCate',
						element: <ArticleCate />,
					},
				]
			},
			{
				path: 'member',
				// element: <Default />,
				children:[
					{
						path: 'memberList',
						element: <Member />,
					},
					{
						path: 'memberInquiry',
						element: <Inquiry />,
					},
					{
						path: 'memberVisit',
						element: <Visit />,
					},
					{
						path: 'forms',
						element: <Forms />,
					},
				]
			},
			{
				path: 'product',
				// element: <Default />,
				children:[
					{
						path: 'productList',
						element: <Product />,
					},
					{
						path: 'productCate',
						element: <ProductCate />,
					},
					// {
					// 	path: 'typeCate',
					// 	element: <TypeCate />,
					// },
					{
						path: 'productTpl',
						element: <Tpl />,
					},
					{
						path: 'productParams',
						element: <Params />,
					},
				]
			},
			{
				path: 'userAuth',
				// element: <Default />,
				children:[
					{
						path: 'user',
						element: <User />,
					},
					{
						path: 'role',
						element: <Role />,
					},
				]
			},
			{
				path: 'system',
				// element: <Default />,
				children:[
					{
						path: 'config',
						element: <Config />,
					},
					{
						path: 'ads',
						element: <Ads />,
					},
					{
						path: 'singlePage',
						element: <SinglePage />,
					},
					{
						path: 'logs',
						element: <Logs />,
					},
				]
			},
		]
	},
]