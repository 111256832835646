export const langList = {
    'zh': {
        g_search:'查询',
        g_add:'添加数据',
        g_manage:'管理',
        g_save:'保存',
        g_edit:'编辑',
        g_del:'删除',
        g_parent:'父级分类',
        g_create_at:'创建时间',
        g_sort:'排序',
        g_verify:'审核',
        g_thumb:'缩略图',
        g_title:'标题',
        g_category:'分类',
        g_email:'邮箱',
        g_phone:'电话',
        g_status:'状态',
        g_status_enabled:'正常',
        g_status_disabled:'禁用',
        g_remarks:'备注说明',
        g_keyword:'关键词',
        g_desc:'描述',
        g_content:'内容',
        g_export_excel:'导出Excel',
        g_reminder:'温馨提示',
        g_ok:'确定',
        g_cancel:'取消',
        g_comfirm_del:'您确定要删除此条数据吗？',
        g_comfirm_verify:'审核通过后会立即发布',
        g_operate_succ:'操作成功！',
        g_operate_failed:'操作失败！',
        g_del_succ:'数据已删除！',

        g_welcome:'欢迎登录后台！',

        // 用户登录页面
        sys_title: '欢迎登录后台管理系统',
        login_title: '欢迎登录后台管理系统',
        login_account: '请输入您的账号',
        login_pwd: '请输入您的密码',
        login_btn: '立即登录',

        logo_title:'钰工后台管理系统',
        layout_edit_profile :'修改资料',
        layout_exit :'安全退出',

        profile_account:'用户账号',
        profile_old_pwd:'原来密码',
        profile_new_pwd:'新的密码',
        profile_if_change:'不改请留空',
        profile_real_name:'真实姓名',
        profile_phone:'联系电话',

        // 文章页面
        article_bread_one: '文章管理',
        article_bread_two: '列表',
        article_bread_two_cate: '文章分类',
        article_attr: '属性',
        article_visit: '浏览次数',
        article_publisher: '发布人',
        article_attach: '附件下载',
        video_url: '视频地址',
        article_video: '视频',
        article_as_root:'作为根分类',
        article_created:'发布时间',

        // 产品页面
        pro_bread_one: '产品管理',
        pro_bread_two: '列表',
        pro_bread_two_cate: '产品分类',
        pro_title: '产品名称',
        pro_cate: '产品分类',
        pro_is_sale: '产品状态',
        pro_verify: '审核状态',
        pro_upload: '上传文件',
        pro_import: '导入数据',
        pro_upload_select: '选择文件',
        pro_down_tpl: '下载模板',
        pro_btn_import: '导入数据',
        pro_btn_cancel: '取消',
        pro_tab_info:'产品信息',
        pro_tab_high:'高级设置',
        pro_form_pro_title:'产品标题',
        pro_form_desc:'产品概述',
        pro_form_intro:'业务数据',
        pro_form_adv:'技术参数',
        pro_form_app:'应用范围',

        pro_form_relate:'关联产品',
        pro_form_relate_add:'选择关联产品',
        pro_add_spec:'添加规格',
        pro_select_img:'选图',
        pro_cate_no_params:'请选择分类或配置参数',

        params_title:'参数',
        params_name:'参数',
        params_filter:'是否筛选',

        // 会员
        m_bread_one:'会员管理',
        m_bread_two:'会员列表',
        m_placeholder_keyword:'邮箱 / 电话',

        m_inquiry_two: '询价记录',
        m_inquiry_content: '询价内容',
        m_placeholder_inquiry:'姓名 / 邮箱 / 手机 / 内容',

        m_visit_bread_two:'浏览足迹',

        // 角色权限
        p_bread_one:'用户权限',
        p_bread_two:'角色权限',
        p_placeholder_keyword:'角色',
        p_bread_two_user:'用户管理',
        u_placeholder_keyword:'账号 / 手机',
        u_account_label:'用户账号',
        u_pwd_label:'用户密码',
        u_role_label:'所属角色',

        // 系统管理
        s_title:'系统管理',
        s_site_config:'站点设置',
        s_site_title:'站点名称',
        s_site_keywords:'关键词',
        s_site_description:'描述',
        s_site_icp:'ICP备案号',
        s_site_js:'第三方JS代码',
        s_site_article_verify:'文章是否审核发布',
        s_site_pro_verify:'产品是否审核发布',
        s_site_switch_yes:'是',
        s_site_switch_no:'否',
        s_site_inquiry_email:'询价通知邮箱',
        s_site_per_line:'一行填写一个邮箱',

        s_forms_title:'在线表单',
        s_forms_keyword_placeholder:'联系人 / 邮箱 / 电话 / 公司名称',
        s_forms_name:'联系人',
        s_forms_company:'公司名称',
        s_forms_content:'留言内容',
        s_forms_code:'邮政编码',
        s_forms_address:'地址',

        s_ads_title:'广告管理',
        s_ads_keyword_placeholder:'广告标识 / 备注说明',
        s_ads_type:'广告类型',
        s_ads_identification:'广告标识',
        s_ads_remarks:'备注说明',
        s_ads_setting:'设置内容',
        s_ads_img_text:'图文',

        s_page_title:'单页管理',
        s_page_name:'单页名称',
        s_page_identification:'标识名称',

        

    },
    'en': {
        g_search:'Search',
        g_add:'add data',
        g_manage:'manage',
        g_save:'save',
        g_edit:'edit',
        g_del:'delete',
        g_parent:'parent',
        g_create_at:'create_at',
        g_sort:'sort',
        g_verify:'verify',
        g_thumb:'thumbnail',
        g_title:'title',
        g_category:'category',
        g_email:'email',
        g_phone:'phone',
        g_status:'status',
        g_status_enabled:'enabled',
        g_status_disabled:'disabled',
        g_remarks:'remarks',
        g_keyword:'keywords',
        g_desc:'description',
        g_content:'content',
        g_export_excel:'export excel',
        g_reminder:'Reminder',
        g_ok:'OK',
        g_cancel:'Cancel',
        g_comfirm_del:'Are you sure you want to delete this data?',
        g_comfirm_verify:'It will be released immediately after approval',
        g_operate_succ:'Operation succeeded！',
        g_operate_failed:'Operation failed！',
        g_del_succ:'Data deleted！',

        g_welcome:'Welcome to management system！',

        sys_title: 'Welcome to Login Yugong',
        login_title: <>
            <p style={{ margin: 0 }}>Welcome to Login</p>
        </>,
        login_account: 'Please enter account',
        login_pwd: 'Please enter password',
        login_btn: 'Sign In',

        logo_title:'Yugong Management System',
        layout_edit_profile :'Profile',
        layout_exit :'Sign Out',

        profile_account:'account',
        profile_old_pwd:'old password',
        profile_new_pwd:'new password',
        profile_if_change:'please leave blank if not changed',
        profile_real_name:'real name',
        profile_phone:'phone',

        // 文章页面
        article_bread_one: 'article',
        article_bread_two: ' list',
        article_attr: 'attribute',
        article_visit: 'visit',
        article_publisher: 'publisher',
        article_attach: 'attachment',
        video_url: 'video url',
        article_video: 'video',
        article_as_root:'as root category',
        article_created:'create time',

        // 产品页面
        pro_bread_one: 'product',
        pro_bread_two: 'list',
        pro_bread_two_cate: 'category',
        pro_title: 'product title',
        pro_cate: 'category',
        pro_is_sale: 'sale',
        pro_verify: 'verify',
        pro_import: 'import',
        pro_upload: 'upload',
        pro_upload_select: 'select file',
        pro_down_tpl: 'download template',
        pro_btn_import: 'import data',
        pro_btn_cancel: 'cancel',
        pro_tab_info:'product',
        pro_tab_high:'advanced',
        pro_form_pro_title:'title',
        pro_form_desc:'Overview',
        pro_form_intro:'Business',// data
        pro_form_adv:'Technical',// data
        pro_form_app:'applications',
        pro_form_relate:'related',
        pro_form_relate_add:'add associated products',
        pro_add_spec:'add specifications',
        pro_select_img:'image',
        pro_cate_no_params:'Please select classification or set parameters',

        params_title:'parameter',
        params_name:'parameter',
        params_filter:'filter',

        // 会员
        m_bread_one:'member',
        m_bread_two:' list',
        m_placeholder_keyword:'email / phone',

        m_inquiry_two: 'inquiry',
        m_inquiry_content: 'content',
        m_placeholder_inquiry:'name / email / phone / content',

        m_visit_bread_two:'visit',

        // 角色权限
        p_bread_one:'permission',
        p_bread_two:'role permission',
        p_placeholder_keyword:'role',
        p_bread_two_user:'user',
        u_placeholder_keyword:'account / phone',
        u_account_label:'account',
        u_pwd_label:'password',
        u_role_label:'role',

        // 系统管理
        s_title:'system',
        s_site_config:'config',
        s_site_title:'web title',
        s_site_keywords:'keywords',
        s_site_description:'description',
        s_site_icp:'ICP',
        s_site_js:'third js',
        s_site_article_verify:'article verify',
        s_site_pro_verify:'product verify',
        s_site_switch_yes:'yes',
        s_site_switch_no:'no',
        s_site_inquiry_email:'inquiry inform email',
        s_site_per_line:'fill in one mailbox per line',

        s_forms_title:'form',
        s_forms_keyword_placeholder:'name / email / phone / company',
        s_forms_name:'name',
        s_forms_company:'company',
        s_forms_code:'post code',
        s_forms_address:'address',
        s_forms_content:'content',

        s_ads_title:'ads',
        s_ads_keyword_placeholder:'identification / remarks',
        s_ads_type:'ads type',
        s_ads_identification:'identification',
        s_ads_remarks:'remarks',
        s_ads_setting:'setting',
        s_ads_img_text:'image & text',

        s_page_title:'single page',
        s_page_name:'page name',
        s_page_identification:'identification',

    }
}