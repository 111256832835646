import axios from 'axios';
import { message } from 'antd';

message.config({
    top: `3px`,
    duration: 2,
    maxCount: 1,
});

// axios 实例配置
const http = axios.create({
    baseURL: '/api',
    timeout: 180 * 1000,
});

// 添加请求拦截器
http.interceptors.request.use(config => {
    // console.log(config.url.includes("admin"),"config",config);
    if (config.headers['method'] === 'post') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    if (!config.headers['Authorization'] && localStorage.getItem('Authorization')) {
        config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('Authorization')
    }
    config.headers['Admin-Language'] = localStorage.getItem('language')
    return config
}, error => {
    return Promise.reject(error);
})

// 添加响应拦截器
http.interceptors.response.use(response => {
    if (response.headers && response.headers['admin-token']) {

    }
    // console.log("response.headers['lang']",response.headers['lang'])
    if (response.status === 200) { // http status
        if (typeof response.data === "string") {
            message.error(response.data);
            return Promise.reject(response.data);
        }
        const { code, msg } = response.data;
        if (code === 401) {
            message.error(msg);
            window.location.href = "/"
            return Promise.reject(msg);
        } else if (code === 402) {  // 后台自定义 status
            message.error(msg);
            setTimeout(()=>{
                window.history.go(-1)
            },2000)
            return Promise.reject(msg);
        } else if ([200, 201, 204].includes(code)) {  // 后台自定义 status
            return response.data;
        } else {
            message.error(msg);
            return Promise.reject(msg);
        }
    } else {
        message.error(response);
        return Promise.reject(response.data);
    }

}, error => {
    return Promise.reject(error);
});

export default http;