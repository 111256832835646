import React, { useState, useEffect } from 'react'
import { Table, Button, Form, Input, Select, message, Modal, Pagination } from "antd"
import { ExclamationCircleOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import BaseModal from '../../components/modal/BaseModal'
import { userList, userHandle, userDel, roleList } from "../../api"
import { useLangContext } from '../../utils/context'

const { confirm } = Modal;
const { Option } = Select;
function User() {
	const { langConfig } = useLangContext();
	const [modalConfig, setModalConfig] = useState({ visible: false, width: 550, title: '' })
	const [modalData, setModalData] = useState([])
	const [tableData, setTableData] = useState([])
	const [pageInfo, setPageInfo] = useState({ page: 1, page_size: 5, total: 10 })
	const [loading, setLoading] = useState(true)
	const [roleOption, setRoleOption] = useState([])

	useEffect(() => {
		roleList({ page: 1, page_size: 50, }).then(res => {
			if (res.code === 200) {
				setRoleOption(res.data.data)
			}
		}).catch(err => {
			console.log(err)
		})
	}, [])
	const [formQuery] = Form.useForm();
	const onQueryFinish = (values) => {
		// console.log("onFinish", values);
		if (pageInfo.page === 1) {
			getData()
		} else {
			setPageInfo({ ...pageInfo, page: 1 })
		}
	};

	// 表格模块
	const tableColumns = [
		{
			title: langConfig.u_account_label,
			dataIndex: 'account',
			key: 'account',
		},
		{
			title: langConfig.profile_real_name,
			dataIndex: 'real_name',
			key: 'real_name',
		},
		{
			title: langConfig.g_phone,
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: langConfig.u_role_label,
			dataIndex: 'role_title',
			key: 'role_title',
		},
		{
			title: langConfig.g_create_at,
			dataIndex: 'created_at',
			key: 'created_at',
			width: 180
		},
		{
			title: langConfig.g_manage,
			key: 'Manage',
			width: '115px',
			render: (text, record) => {
				return <>
					<Button type='link' size='small' onClick={() => showEdit(record)}>{langConfig.g_edit}</Button>
					<Button type='link' size='small' onClick={() => delData(record.id)}>{langConfig.g_del}</Button>
				</>
			},
		},
	]
	const showAdd = () => {
		form.resetFields();
		setModalData([]);
		setModalConfig({ ...modalConfig, visible: true, title: langConfig.g_add })
	}
	const showEdit = (row) => {
		form.resetFields();
		form.setFieldsValue({ ...row })
		setModalData(row);
		setModalConfig({ ...modalConfig, visible: true, title: langConfig.g_edit })
	}

	const delData = (id) => {
		confirm({
			title: langConfig.g_reminder,
			icon: <ExclamationCircleOutlined />,
			content: langConfig.g_comfirm_del,
			cancelText: langConfig.g_cancel,
			okText: langConfig.g_ok,
			onOk() {
				userDel({ id: id }).then(res => {
					getData()
					// message.success("数据已删除！")
					message.success(langConfig.g_del_succ)
				}).catch(err => {
					console.log(err)
				})

			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}

	const getData = () => {
		setLoading(true)
		userList({ ...pageInfo, type: 1, ...formQuery.getFieldsValue() }).then(res => {
			if (res.code === 200) {
				setTableData(res.data.data)
				setPageInfo({ ...pageInfo, total: res.total })
				setLoading(false)
			}
		}).catch(err => {
			console.log(err)
			setLoading(false)
		})
	}
	useEffect(() => {
		getData();
	}, [pageInfo.page])

	// 分页设置
	const onPageChange = (page) => {
		setPageInfo({ ...pageInfo, page: page })
	}

	// 弹出层
	const [form] = Form.useForm();
	const onFinish = (values) => {
		userHandle({ ...values, id: modalData.id || "" }).then(res => {
			setModalConfig({ ...modalConfig, visible: false })
			getData();
			message.info(res.msg)
		}).catch(err => {
			console.log(err)
		})

	};
	const layout = {
		labelCol: {
			span: 5,
		},
		wrapperCol: {
			span: 18,
		},
	};
	const tailLayout = {
		wrapperCol: {
			offset: 20,
			span: 4,
		},
	};
	const modalHandle = (data) => {
		setModalConfig({ ...modalConfig, visible: false })
	}

	return (
		<div>
			<div className='breaccrumb-box'>
				<div className='breaccrumb-left'><span>{langConfig.p_bread_one} / </span>{langConfig.p_bread_two_user}</div>
				<div className='breaccrumb-right'><Button type="primary" size='small' onClick={() => showAdd()}> <PlusOutlined />{langConfig.g_add}</Button></div>
			</div>

			<div className='query-form-box'>
				<Form layout="inline" form={formQuery} onFinish={onQueryFinish}>
					<Form.Item name="keyword" label="">
						<Input allowClear placeholder={langConfig.u_placeholder_keyword} />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit" ghost>
							<SearchOutlined />
							{langConfig.g_search}
						</Button>
					</Form.Item>
				</Form>
			</div>

			<Table size='small' rowKey='id' columns={tableColumns} dataSource={tableData} pagination={false} loading={loading} />
			<div className="page-box">
				<Pagination current={pageInfo.page} total={pageInfo.total} pageSize={pageInfo.page_size} onChange={onPageChange} />
			</div>

			<BaseModal config={modalConfig} data={modalData} handle={modalHandle}>
				<Form {...layout} form={form} onFinish={onFinish}>
					<Form.Item name="role_id" label={langConfig.u_role_label}
						rules={[
							{
								required: true,
								message: ''
							},
						]}
					>
						<Select allowClear >
							{
								roleOption.map(item=>{
									return <Option value={item.id} >{item.title}</Option>
								})
							}
							
						</Select>
					</Form.Item>
					<Form.Item name="account" label={langConfig.profile_account}
						rules={[
							{
								required: true,
								message: ''
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item name="password" label={langConfig.u_pwd_label}>
						<Input.Password />
					</Form.Item>
					<Form.Item name="real_name" label={langConfig.profile_real_name}
						rules={[
							{
								required: true,
								message: ''
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item name="phone" label={langConfig.g_phone}>
						<Input />
					</Form.Item>
					<Form.Item {...tailLayout}>
						<Button type="primary" htmlType="submit">
						{langConfig.g_save}
						</Button>
					</Form.Item>
				</Form>
			</BaseModal>
		</div>
	)
}

export default User