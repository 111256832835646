import React, { useState, useEffect } from 'react'
import { Table, Button, Form, Input, message, Modal, Pagination } from "antd"
import { ExclamationCircleOutlined, SearchOutlined, CloudDownloadOutlined, PropertySafetyOutlined } from '@ant-design/icons';
import { visitList, visitDel } from "../../api"
import { useLangContext } from '../../utils/context'
import "./member.scss"

const { confirm } = Modal;
function Visit(props) {
	const { langConfig } = useLangContext();
	const [tableData, setTableData] = useState([])
	const [pageInfo, setPageInfo] = useState({ page: 1, page_size: 10, total: 10 })
	const [loading, setLoading] = useState(true)

	const [formQuery] = Form.useForm();
	const onQueryFinish = (values) => {
		// console.log("onFinish", values);
		if (pageInfo.page === 1) {
			getData()
		} else {
			setPageInfo({ ...pageInfo, page: 1 })
		}
	};

	// 表格模块
	const tableColumns = [
		{
			title: "url",
			dataIndex: 'url',
			key: 'url',
			render: (text, record) => {
				return <a href={record.url} target="_blank" rel='noreferrer'>{record.url}</a>
			},
		},
		{
			title: "email",
			dataIndex: 'email',
			key: 'email',
			width: 200
		},
		{
			title: "ip",
			dataIndex: 'ip',
			key: 'ip',
			width: 180
		},
		{
			title: langConfig.g_create_at,
			dataIndex: 'created_at',
			key: 'created_at',
			width: 180
		},
		{
			title: langConfig.g_manage,
			key: 'Manage',
			width: '65px',
			render: (text, record) => {
				return <div>
					<Button type='link' size='small' onClick={() => delData(record.id)}>{langConfig.g_del}</Button>
				</div>
			},
		},
	]

	const delData = (id) => {
		confirm({
			title: langConfig.g_reminder,
			icon: <ExclamationCircleOutlined />,
			content: langConfig.g_comfirm_del,
			cancelText: langConfig.g_cancel,
			okText: langConfig.g_ok,
			onOk() {
				visitDel({ id: id }).then(res => {
					getData()
					// message.success("数据已删除！")
					message.success(langConfig.g_del_succ)
				}).catch(err => {
					console.log(err)
				})

			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}

	const getData = () => {
		setLoading(true)
		visitList({ ...pageInfo, ...formQuery.getFieldsValue() }).then(res => {
			if (res.code === 200) {
				setTableData(res.data.data)
				setPageInfo({ ...pageInfo, total: res.data.total })
				setLoading(false)
			}
		}).catch(err => {
			console.log(err)
			setLoading(false)
		})
	}
	useEffect(() => {
		getData();
	}, [pageInfo.page, pageInfo.page_size])

	// 分页设置
	const onPageChange = (val, page_size) => {
		setPageInfo({ page_size, page: val })
	}
	const onShowSizeChange = (current, page_size) => {
		setPageInfo({ page: 1, page_size })
	}

	const exportExcel = () => {
		message.info("正在导出，请稍候...")
	}

	return (
		<div>
			<div className='breaccrumb-box'>
				<div className='breaccrumb-left'><span>{langConfig.m_bread_one} / </span>{langConfig.m_visit_bread_two}</div>
				<div className='breaccrumb-right'>
					{/* <Button type="primary" size='small' onClick={() => exportExcel()}> <CloudDownloadOutlined />{langConfig.g_export_excel}</Button> */}
				</div>
			</div>

			<div className='query-form-box'>
				<Form layout="inline" form={formQuery} onFinish={onQueryFinish}>
					<Form.Item name="keyword" label="">
						<Input allowClear placeholder="url / email / ip" />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit" ghost>
							<SearchOutlined />
							{langConfig.g_search}
						</Button>
					</Form.Item>
				</Form>
			</div>

			<Table size='small' rowKey='id' columns={tableColumns} dataSource={tableData} pagination={false} loading={loading} />
			<div className="page-box">
				<Pagination
					current={pageInfo.page}
					total={pageInfo.total}
					pageSize={pageInfo.page_size}
					onChange={(page, pageSize) => onPageChange(page, pageSize)}
					pageSizeOptions={[10, 30, 60, 100]}
					onShowSizeChange={(current, size) => onShowSizeChange(current, size)}
				/>
			</div>
		</div>
	)
}

export default Visit