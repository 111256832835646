import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, message } from "antd"
import "../assets/styles/style.scss"
import { loginHandle } from "../api"
import { useLangContext } from '../utils/context'

function Login() {
    const { langConfig } = useLangContext();

    const navigate = useNavigate();
    const [form] = Form.useForm();
    useEffect(() => {
        form.resetFields();
        document.title = langConfig.sys_title
        // eslint-disable-next-line
    }, [])

    const onFinish = (values) => {
        loginHandle({ ...values }).then(res => {
            if (res.code === 200) {
                localStorage.setItem("Authorization", res.data.token);
                localStorage.setItem("login_account", res.data.account);
                navigate("/admin/home")
            } else {
                message.error(res.msg)
            }
        }).catch(err => {
            console.log(err)
        })
    };
    const layout = {
        wrapperCol: {
            span: 24,
        },
    };

    return (
        <div className='login-container'>
            {/* <img src="/images/login-bg.jpg" /> */}
            <div></div>

            <div className="login-box">
                <div className='login-img'></div>

                <div className='login-form'>
                    <Form {...layout} form={form} onFinish={onFinish}>
                        <div className="login-title">{langConfig.login_title}</div>
                        <Form.Item name="account" label="" rules={[
                            {
                                required: true,
                                message: langConfig.login_account,
                            },
                        ]}>
                            <Input placeholder={langConfig.login_account} allowClear />
                        </Form.Item>
                        <Form.Item name="password" label="" rules={[
                            {
                                required: true,
                                message: langConfig.login_pwd,
                            },
                        ]} >
                            <Input.Password placeholder={langConfig.login_pwd} allowClear />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                {langConfig.login_btn}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default Login