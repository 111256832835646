import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Switch, message } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { getBasic, saveBasic } from "../../api"
import { useLangContext } from '../../utils/context'

const { TextArea } = Input;

function Config() {
	const { langConfig } = useLangContext();
	useEffect(() => {
		getData()
		// eslint-disable-next-line
	}, [])

	const [loading, setLoading] = useState(true);

	const [dataSource, setDataSource] = useState({})

	// 基本配置表单
	const [formBasic] = Form.useForm();
	const handleBasicSubmit = async () => {
		try {
			const values = await formBasic.validateFields();
			console.log(values)
			saveData(values)
		} catch (errorInfo) {
			console.log('handleSubmit Failed:', errorInfo);
		}
	};

	// 获取数据
	const getData = () => {
		setLoading(true);
		getBasic({}).then(res => {
			let _data = res.data;
			_data.article_verify = _data.article_verify === 1 ? true : false
			_data.product_verify = _data.product_verify === 1 ? true : false
			setDataSource(_data);
			formBasic.setFieldsValue(_data)
			setLoading(false);
		}).catch(err => {
			setLoading(false);
		})
	}

	const saveData = (formvalues) => {
		saveBasic({ ...dataSource, ...formvalues }).then(res => {
			if (res.code === 200) {
				message.info(res.msg)
			}
		}).catch(err => {
			console.log(err)
		})
	}
	return (
		<>
			<div className='breaccrumb-box'>
				<div className='breaccrumb-left'><span>{langConfig.s_title} / </span>{langConfig.s_site_config}</div>
				<div className='breaccrumb-right'></div>
			</div>
			<div style={{ width: '700px', paddingTop: '20px' }}>
				<Form form={formBasic} labelCol={{ span: 6, }} wrapperCol={{ span: 18, }} >
					<Form.Item label={langConfig.s_site_title} name="seo_title" rules={[
						{
							required: true,
							message: ''
						},
					]}><Input /></Form.Item>
					<Form.Item label={langConfig.s_site_keywords} name="seo_keyword"><Input /></Form.Item>
					<Form.Item label={langConfig.s_site_description} name="seo_description"><TextArea rows={3} /></Form.Item>
					{/* <Form.Item label="自定义热搜词" name="hot_search"><Input placeholder='多个请用 ， 隔开' /></Form.Item> */}
					{/* <Form.Item label={langConfig.s_site_icp} name="icp"><Input /></Form.Item>
					<Form.Item label={langConfig.s_site_js} name="js_code"><TextArea rows={3} placeholder="" /></Form.Item> */}
					{/* <div style={{ height: '25px' }}></div> */}
					<Form.Item label={langConfig.s_site_article_verify} name="article_verify" valuePropName="checked" >
						<Switch checkedChildren={langConfig.s_site_switch_yes} unCheckedChildren={langConfig.s_site_switch_no} />
					</Form.Item>
					<Form.Item label={langConfig.s_site_pro_verify} name="product_verify" valuePropName="checked" >
					<Switch checkedChildren={langConfig.s_site_switch_yes} unCheckedChildren={langConfig.s_site_switch_no} />
					</Form.Item>
					<Form.Item label={langConfig.s_site_inquiry_email} name="notice_email"><TextArea placeholder={langConfig.s_site_per_line} rows={5} /></Form.Item>
					<Form.Item wrapperCol={{ span: 18, offset: 6 }}>
						<Button type="primary" onClick={handleBasicSubmit} icon={<SaveOutlined />}>{langConfig.g_save}</Button>
					</Form.Item>
				</Form>
			</div>
		</>
	)
}

export default Config