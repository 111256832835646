import React, { useEffect, useState } from 'react'
import { ShoppingCartOutlined, TeamOutlined, RollbackOutlined, FundProjectionScreenOutlined, PropertySafetyOutlined } from '@ant-design/icons';
import { useLangContext } from '../../utils/context'

function Home() {
	// const [pannelData, setPannelData] = useState({ total_money: "231", order_count: 2186, member_count: 2698, unpaid_count: 27, after_sale_count: 15 })
	const { langConfig } = useLangContext();
	return (
		<div>
			{/* <div className='top-pannel'>
				<li>
					<div className='calc-box-item-left'><TeamOutlined style={{ color: "#4ecccb" }} /></div>
					<div className='calc-box-item-right'>
						<div>{pannelData.member_count}</div>
						<div>会员总数</div>
					</div>
				</li>
				<li>
					<div className='calc-box-item-left'><PropertySafetyOutlined style={{ color: "#8834ea" }} /></div>
					<div className='calc-box-item-right'>
						<div>{pannelData.total_money}</div>
						<div>表单留言</div>
					</div>
				</li>
				<li>
					<div className='calc-box-item-left'><FundProjectionScreenOutlined style={{ color: "#3da3ff" }} /></div>
					<div className='calc-box-item-right'>
						<div>{pannelData.order_count}</div>
						<div>收藏总数</div>
					</div>
				</li>
				<li>
					<div className='calc-box-item-left'><ShoppingCartOutlined style={{ color: "#febc25" }} /></div>
					<div className='calc-box-item-right'>
						<div>{pannelData.unpaid_count}</div>
						<div>询价总数</div>
					</div>
				</li>
			</div> */}

			<div style={{textAlign:"center", paddingTop:"100px",fontSize:"26px"}}>{langConfig.g_welcome}</div>
		</div>
	)
}

export default Home