import React, { useState, useEffect, useRef } from 'react'
import { message, Modal } from 'antd';
// import { SearchOutlined, } from '@ant-design/icons';
import FileBox from './FileBox';

function FileBoxModal(props) {
    const [selectFile, setSelectedFile] = useState([]);
    const { config, handle } = props;
    const handleOk = () => {
        if (selectFile.length === 0) {
            message.error("请选择文件！")
            return false;
        }
        handle({ type: 'ok', data: selectFile })
    };

    const handleCancel = () => {
        handle({ type: 'cancel', data: [] })
    };
    const parentHandleSelFile = (data) => {
        console.log("parentHandleSelFile", data)
        setSelectedFile(data)
    }
    return (
        <div>
            <Modal key={config.fileboxKey} title="" zIndex={100000} visible={config.visible} width={config.width} onOk={handleOk} onCancel={handleCancel} okText="确定" cancelText="取消" maskClosable={false}>
                <FileBox key={config.fileboxKey} max_select_count={config.max_select_count} parentHandleSelFile={parentHandleSelFile}></FileBox>
            </Modal>
        </div>
    )
}

export default FileBoxModal