import React, { useState, useEffect } from 'react'
import { Button, Form, Input, message, Spin } from "antd"
import { profile, profileSave } from "../../api"
import { useLangContext } from '../../utils/context'

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 7,
    span: 16,
  },
};

function Profile() {
  const { langConfig } = useLangContext();
  const [loading,setLoading] = useState(true);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    profileSave({ ...values }).then(res => {
      if (res.code === 200) {
        message.success(res.msg)
      }
    }).catch(err => {
      console.log(err)
    })

  };

  const getData = () => {
    setLoading(true)
    profile({}).then(res => {
      if (res.code === 200) {
        form.setFieldsValue(res.data)
        setLoading(false)
      }
    }).catch(err => {
      console.log(err)
      setLoading(false)
    })
  }
  useEffect(() => {
    getData();
  }, [])

  return (
    <div style={{ width: '500px', paddingTop: "30px" }}>
      <Spin spinning={loading}>
        <Form {...layout} form={form} onFinish={onFinish}>
          <Form.Item name="account" label={langConfig.profile_account}>
            <Input disabled />
          </Form.Item>
          <Form.Item name="password" label={langConfig.profile_old_pwd}>
            <Input.Password placeholder={langConfig.profile_if_change} />
          </Form.Item>
          <Form.Item name="new_password" label={langConfig.profile_new_pwd}>
            <Input.Password placeholder={langConfig.profile_if_change} />
          </Form.Item>
          <Form.Item name="real_name" label={langConfig.profile_real_name}
            rules={[
              {
                required: true,
                message: ''
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="phone" label={langConfig.profile_phone}
            rules={[
              {
                required: true,
                message: ''
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
            {langConfig.g_save}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  )
}

export default Profile