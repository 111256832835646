import React from 'react'

function FileDocument(props) {
    const handleItemClick = (obj) => {
        props.selectedCallBack(obj)
    }

    return (
        <div className='tab-box-file-body'>
            {
                props.data.length > 0 && props.data.map(item => {
                    return (
                        <li className={props.selectedItem.includes(item) ? 'tab-item-file-active' : ''} key={item.id} onClick={() => { handleItemClick(item) }}>
                            <div>{item.original_name}</div>
                            <div>{item.size}</div>
                            <div>{item.created_at}</div>
                        </li>
                    )
                })
            }

        </div>
    )
}

export default FileDocument
