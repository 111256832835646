import React, { useState, useEffect } from 'react'
import { Input, Button, Switch, message } from 'antd';
import { nanoid } from 'nanoid';
import FileBoxModal from '../../components/fileBox/FileBoxModal'
// import { specDel } from "../../api"
import { useLangContext } from '../../utils/context'

function SpecTableCom(props) {
	const { langConfig } = useLangContext();
    const [tableData, setTableData] = useState([])

    const { initSpecData, proItemParams, parHandleSpec } = props;
    // 

    // let item_title = ['thumb_id','part number','total_length','sort'] // 固定参数
    // 动态参数
    let params_title = []
    proItemParams.forEach(item => {
        params_title.push(item.title);
    })

    useEffect(() => {
        console.log('initSpecData', initSpecData)
        if (initSpecData.length === 0) {
            // 添加数据
            setTableData([])
            // addItem()
        } else {
            // 编辑数据
            let _tableData = [];
            if (initSpecData.length > 0) {
                let _item;
                initSpecData.forEach(item => {
                    _item = { id: item.id, thumbs: item.thumbs, part_no: item.part_no,  sort: item.sort }
                    item.order_params.forEach(item_params => {
                        _item[item_params] = item[item_params]
                    })
                    _tableData.push(_item)
                })
            }
            setTableData([..._tableData])
            // parHandleSpec(_tableData)
        }

    }, [initSpecData])

    const addItem = () => {
        if (proItemParams.length > 0) {
            let _item = { id: '', thumbs: [], part_no: '', sort: '0' }
            proItemParams.forEach(item => {
                _item['params_' + item.id] = '' //tableData.length // ''
            })
            setTableData([...tableData, _item])
        }

    }
    const delItem = (index) => {
        // let arr = [{id:1},{id:2},{id:3},{id:4},{id:5}]
        // console.log("arr",arr)
        // arr.splice(2,1);
        // console.log("arr",arr)
        let _tableData = JSON.parse(JSON.stringify(tableData))
        console.log("del index _tableData", index, _tableData)
        _tableData.splice(index, 1)
        console.log("del index _tableData", index, _tableData)
        setTableData([..._tableData])
        parHandleSpec(_tableData)
    }
    // 编辑规格
    const editItem = (e, index, field) => {
        let _tableData = JSON.parse(JSON.stringify(tableData));
        _tableData[index][field] = e.target.value.trim()
        console.log("_tableData", _tableData)
        setTableData([..._tableData])
        parHandleSpec(_tableData)
    }


    // 启用或禁用某个型号
    // const editProductStatus = (index) => {
    //     let _data = tableData;
    //     _data[index]['status'] = _data[index]['status'] === 1 ? 2 : 1
    //     setTableData([..._data])
    //     props.parHandleSpec(_data)
    // }

    // 文件管理配置
    const [fileBoxConfig, setFileBoxConfig] = useState({ visible: false, max_select_count: 1, width: 1000, fileboxKey: nanoid(), item_index: null })
    const fileBoxHanlde = (obj) => {
        if (obj.type === 'ok') {
            let index = fileBoxConfig.item_index
            tableData[index].thumbs = obj.data;
            setTableData([...tableData]);
            props.parHandleSpec(tableData)
        }
        setFileBoxConfig({ ...fileBoxConfig, visible: false, item_index: null });
    }
    const showUploadThumbBox = (index) => {
        setFileBoxConfig({ ...fileBoxConfig, visible: true, fileboxKey: nanoid(), item_index: index });
    }
    const handleDelThumb = (index) => {
        tableData[index].thumbs = [];
        props.parHandleSpec(tableData)
        setTableData([...tableData]);
    }

    return (
        <>
            {
                <div style={{ width: '955px', margin: '0' }}>

                    <table className='spec-table'>
                        <thead className='spec-table-title'>
                            <tr>
                                <th>image</th>
                                <th>part number</th>
                                <th>sort</th>
                                {
                                    proItemParams.map((item, index) => {
                                        return <th key={`title${index}`}>{item.title}</th>
                                    })
                                }
                                <th>{langConfig.g_manage}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tableData.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            {
                                                Object.keys(item).map((rowkey) => {
                                                    return rowkey === 'thumbs' ? <td key={`item${rowkey}`}>
                                                        {
                                                            item.thumbs && item.thumbs.length === 1 ? <div className='spec-thumb-box' key={`thumb${rowkey}`}>
                                                                <div className='spec-thumb-del' onClick={() => { handleDelThumb(index) }}>-</div>
                                                                <div className='spec-thumb-img'>
                                                                    <a href={item.thumbs[0].file_path} target="_blank" rel='noreferrer'><img src={item.thumbs[0].file_path} alt='' style={{ width: '40px', height: '40px', borderRadius: '5px',margin:"0 auto" }} /></a>
                                                                </div>
                                                            </div> : <Button type="link" onClick={() => showUploadThumbBox(index)} key={`thumbbtn${rowkey}`}>{langConfig.pro_select_img}</Button>
                                                        }
                                                    </td> : String(rowkey) === 'id' ? null : <td style={{ textAlign: "left" }} key={`item${rowkey}`}>
                                                        <Input allowClear key={`iteminput${rowkey}`} placeholder="" style={{ width: '100%' }} value={item[rowkey]} onChange={(e) => { editItem(e, index, rowkey) }} />
                                                    </td>
                                                })
                                            }
                                            <td>
                                                <Button type="link" onClick={() => delItem(index)}>{langConfig.g_del}</Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    {proItemParams.length > 0 ?<div style={{ textAlign: "right" }}><a onClick={() => addItem()} style={{ marginTop: '6px' }}>{langConfig.pro_add_spec}</a></div>:<div style={{ textAlign: "center" }}>{langConfig.pro_cate_no_params}</div>}
                    

                </div>
            }


<FileBoxModal config={fileBoxConfig} handle={fileBoxHanlde}></FileBoxModal>
        </>
    )
}

export default SpecTableCom