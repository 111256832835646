import React, { useState, useEffect } from 'react'
import { Table, Button, Form, Input, Select, message, Modal, Pagination, TreeSelect } from "antd"
import { ExclamationCircleOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import BaseModal from '../../components/modal/BaseModal'
import { proParamsList, proParamsHandle, proParamsDel, productCateList } from "../../api"
import { useLangContext } from '../../utils/context'

const { confirm } = Modal;
const { Option } = Select;
function Params() {
    const { langConfig } = useLangContext();
	const [modalConfig, setModalConfig] = useState({ visible: false, width: 550, title: '' })
	const [modalData, setModalData] = useState([])
	const [tableData, setTableData] = useState([])
	const [pageInfo, setPageInfo] = useState({ page: 1, page_size: 10, total: 0 })
	const [loading, setLoading] = useState(true)
	const [cateData, setCateData] = useState([])

	const getCateData = (type = "") => {
		setLoading(true)
		productCateList({ type }).then(res => {
			if (res.code === 200) {
				setCateData([...res.data])
			}
		}).catch(err => {
			console.log(err)
		})
	}
	useEffect(() => {
		getCateData();
	}, [])

	const [formQuery] = Form.useForm();
	const onQueryFinish = (values) => {
		// console.log("onFinish", values);
		if (pageInfo.page === 1) {
			getData()
		} else {
			setPageInfo({ ...pageInfo, page: 1 })
		}
	};

	// 表格模块
	const tableColumns = [
		{
			title: langConfig.g_category,
			dataIndex: 'product_cate_label',
			key: 'product_cate_label',
			width: 180
		},
		{
			title: langConfig.params_name,
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: langConfig.params_filter,
			dataIndex: 'filter_flag_label',
			key: 'filter_flag_label',
			width: 180
		},
		{
			title: langConfig.g_sort+' (desc)',
			dataIndex: 'sort',
			key: 'sort',
			width: 180
		},
		{
			title: langConfig.g_create_at,
			dataIndex: 'created_at',
			key: 'created_at',
			width: 180
		},
		{
			title: langConfig.g_manage,
			key: 'Manage',
			width: '125px',
			render: (text, record) => {
				return <>
					<Button type='link' size='small' onClick={() => showEdit(record)}>{langConfig.g_edit}</Button>
					<Button type='link' size='small' onClick={() => delData(record.id)}>{langConfig.g_del}</Button>
				</>
			},
		},
	]
	const showAdd = () => {
		form.resetFields();
		form.setFieldsValue({ filter_flag:2 })
		setModalData([]);
		setModalConfig({ ...modalConfig, visible: true, title: langConfig.g_add })
	}
	const showEdit = (row) => {
		form.resetFields();
		form.setFieldsValue({ ...row })
		setModalData(row);
		setModalConfig({ ...modalConfig, visible: true, title: langConfig.g_edit })
	}

	const delData = (id) => {
		confirm({
            title: langConfig.g_reminder,
			icon: <ExclamationCircleOutlined />,
			content: langConfig.g_comfirm_del,
			cancelText: langConfig.g_cancel,
			okText: langConfig.g_ok,
            onOk() {
                proParamsDel({ id: id }).then(res => {
                    getData();
                    // message.success("数据已删除！")
                    message.success(langConfig.g_del_succ)
                }).catch(err => {
                    console.log(err)
                })

            },
            onCancel() {
                console.log('Cancel');
            },
        });
	}

	const getData = () => {
		setLoading(true)
		proParamsList({ ...pageInfo, type: 1, ...formQuery.getFieldsValue() }).then(res => {
			if (res.code === 200) {
				setTableData(res.data.data)
				setPageInfo({ ...pageInfo, total: res.data.total })
				setLoading(false)
			}
		}).catch(err => {
			console.log(err)
			setLoading(false)
		})
	}
	useEffect(() => {
		getData();
	}, [pageInfo.page])

	const onPageChange = (val, page_size) => {
		setPageInfo({ page_size, page: val })
	}
	const onShowSizeChange = (current, page_size) => {
		setPageInfo({ page: 1, page_size })
	}

	// 弹出层
	const [form] = Form.useForm();
	const onFinish = (values) => {
		proParamsHandle({ ...values, id: modalData.id || "" }).then(res => {
			setModalConfig({ ...modalConfig, visible: false })
			getData();
			message.info(res.msg)
		}).catch(err => {
			console.log(err)
		})

	};
	const layout = {
		labelCol: {
			span: 5,
		},
		wrapperCol: {
			span: 18,
		},
	};
	const tailLayout = {
		wrapperCol: {
			offset: 20,
			span: 4,
		},
	};
	const modalHandle = (data) => {
		setModalConfig({ ...modalConfig, visible: false })
	}

	return (
		<div>
			<div className='breaccrumb-box'>
				<div className='breaccrumb-left'><span>{langConfig.pro_bread_one} / </span>{langConfig.params_title}</div>
				<div className='breaccrumb-right'><Button type="primary" size='small' onClick={() => showAdd()}> <PlusOutlined />{langConfig.g_add}</Button></div>
			</div>

			<div className='query-form-box'>
				<Form layout="inline" form={formQuery} onFinish={onQueryFinish}>
					<Form.Item name="product_cate_id" label="">
						<TreeSelect placeholder={langConfig.g_category} allowClear treeData={cateData} style={{ width: '180px' }} treeDefaultExpandAll />
					</Form.Item>
					<Form.Item name="keyword" label="">
						<Input allowClear placeholder={langConfig.params_name} />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit" ghost>
							<SearchOutlined />
							{langConfig.g_search}
						</Button>
					</Form.Item>
				</Form>
			</div>

			<Table size='small' rowKey='id' columns={tableColumns} dataSource={tableData} pagination={false} loading={loading} />
			<div className="page-box">
				<Pagination
					current={pageInfo.page}
					total={pageInfo.total}
					pageSize={pageInfo.page_size}
					onChange={(page, pageSize) => onPageChange(page, pageSize)}
					pageSizeOptions={[10, 30, 60, 100]}
					onShowSizeChange={(current, size) => onShowSizeChange(current, size)}
				/>
			</div>

			<BaseModal config={modalConfig} data={modalData} handle={modalHandle}>
				<Form {...layout} form={form} onFinish={onFinish}>
					<Form.Item name="product_cate_id" label={langConfig.g_category}
						rules={[
							{
								required: true,
								message: ''
							},
						]}
					>
						<TreeSelect placeholder='' allowClear treeData={cateData}  treeDefaultExpandAll />
					</Form.Item>
					<Form.Item name="title" label={langConfig.params_name}
						rules={[
							{
								required: true,
								message: ''
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item name="filter_flag" label={langConfig.params_filter}
						rules={[
							{
								required: true,
								message: ''
							},
						]}
					>
						<Select allowClear>
							<Option value={1}>是(Yes)</Option>
							<Option value={2}>否(No)</Option>
						</Select>
					</Form.Item>
					<Form.Item name="sort" label={langConfig.g_sort}>
						<Input />
					</Form.Item>
					<Form.Item {...tailLayout}>
						<Button type="primary" htmlType="submit">
						{langConfig.g_save}
						</Button>
					</Form.Item>
				</Form>
			</BaseModal>
		</div>
	)
}

export default Params