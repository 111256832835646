import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate, useLocation } from "react-router-dom"
import { Layout, Menu, Dropdown, Select, Space, Spin } from 'antd';
import {
    DashboardOutlined,
    DownOutlined,
    UserOutlined,
    FormOutlined,
    PoweroffOutlined,
} from '@ant-design/icons';
import * as Iconlibs from '@ant-design/icons';
import "./Admin.scss"
import { useLangContext } from '../../utils/context'
import { getAuthRoutes, getAuthBtns } from "../../api"
import { store, authBtnSlice, authRoutesSlice } from '../../redux/store';

const { Header, Content, Sider } = Layout;

function Admin(props) {
    const [sideMenu, setSideMenu] = useState([]);
    const [isReady, setIsReady] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const { setLang, langConfig } = useLangContext();
    const [currentLang, setCurrentLang] = useState(null);

    useEffect(() => {
        setCurrentLang(localStorage.getItem("language"))
        document.title = langConfig.sys_title

        _getAuthRoutes();
        _getAuthBtns();
    }, [])

    function createIcon(iconName) {
        return React.createElement(
            Iconlibs[iconName],
        )
    }
    const _getAuthRoutes = async () => {
        await getAuthRoutes({}).then(res => {
            let _data = res.data;
            _data.forEach(item => {
                item['icon'] = createIcon(item['icon'])
            })
            let label = localStorage.getItem("language")==='en'?'Dashboard':'仪表盘';
            _data.unshift({ key: 'home', label, icon: <DashboardOutlined /> })
            setSideMenu(_data)
            store.dispatch(authRoutesSlice.actions.setAuthRoutes(_data))
            if (!isReady) {
                setIsReady(true);
            }
        }).catch(err => {
            console.log("err:", err);
        })
    }
    const _getAuthBtns = async () => {
        await getAuthBtns({}).then(res => {
            store.dispatch(authBtnSlice.actions.setAuthBtns(res.data))
            if (!isReady) {
                setIsReady(true);
            }
        }).catch(err => {
            console.log("err:", err);
        })
    }

    const langChangeHandle = (val) => {
        setLang(val);
        window.location.reload();
    }


    const navigate = useNavigate();
    const menuPush = (p) => {
        if (p.keyPath.length === 1) {
            navigate(p.keyPath[0])
        } else if (p.keyPath.length === 2) {
            navigate(p.keyPath[1] + "/" + p.keyPath[0])
        }
    }

    const location = useLocation();
    let defaultSelectedKeysArr = [];
    let defaultOpenKeysArr = [];
    const { pathname } = location;
    const pathname_arr = pathname.split("/");
    if (pathname_arr.length === 4) {
        defaultSelectedKeysArr = [pathname_arr[3]];
        defaultOpenKeysArr = [pathname_arr[2]];
    } else if (pathname_arr.length === 3) {
        defaultSelectedKeysArr = [pathname_arr[2]];
        defaultOpenKeysArr = [];
    }

    const dropMenu = (
        <Menu
            items={[
                {
                    key: 'profile',
                    label: langConfig.layout_edit_profile,
                    icon: <FormOutlined />
                },
                {
                    key: '/',
                    label: langConfig.layout_exit,
                    icon: <PoweroffOutlined />
                },
            ]}
            onClick={(p) => {
                if (p.key === "/") {
                    localStorage.removeItem("Authorization");
                }
                navigate(p.key)
            }}
        />
    );

    return (
        isReady ?
            <Layout className='layout-box' style={{ minHeight: '100vh', overflowY: "hidden", background: "#ffffff" }} >
                <Header className="site-layout-background layout-header-box" style={{ padding: 0, }} >
                    <div className="logo" >{langConfig.logo_title}</div>
                    <div className="user-info-box">
                        <Select
                            size='small'
                            // defaultValue={currentLang}
                            value={currentLang}
                            style={{
                                width: 120,
                                marginRight: "20px"
                            }}
                            onChange={langChangeHandle}
                            options={[
                                {
                                    value: 'zh',
                                    label: '简体中文',
                                },
                                {
                                    value: 'en',
                                    label: 'English',
                                },
                            ]}
                        />
                        <Dropdown overlay={dropMenu}>
                            <Space><UserOutlined />{localStorage.getItem("login_account")} <DownOutlined /></Space>
                        </Dropdown>
                    </div>
                </Header>
                <Layout className="site-layout">
                    <Sider className="site-layout-sider" collapsible={false} collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} >
                        <Menu theme='light' defaultSelectedKeys={defaultSelectedKeysArr} defaultOpenKeys={defaultOpenKeysArr} mode="inline" items={sideMenu} style={{ minHeight: '100%', overflowY: "scroll", }} onClick={p => menuPush(p)} />
                    </Sider>
                    <Content style={{ margin: '0 12px', }} >
                        <div className="site-layout-background main-box" style={{ background: pathname === '/admin/home' ? '' : '#fff' }} >
                            <Outlet />
                        </div>
                    </Content>
                    {/* <Footer style={{ textAlign: 'center', }} >
                    版权所有，侵权必究
                </Footer> */}
                </Layout>
            </Layout> :
            <Spin tip="Loading..." size="large">
                <div style={{ minHeight: '100vh', overflowY: "hidden", background: "#ffffff" }}></div>
            </Spin>

    );
}

export default Admin