import http from "../utils/http";

// export const imgVerifyCode = (params = {}) => http.get("/admin/code", { params });
export const loginHandle = (data = {}) => http.post("/admin/login", data);

// 用户菜单及按纽权限
export const getAuthRoutes = (data = {}) => http.post("/admin/getAuthRoutes", data);
export const getAuthBtns = (data = {}) => http.post("/admin/getAuthBtns", data);

// 个人中心
export const profile = (data = {}) => http.post("/admin/profile", data);
export const profileSave = (data = {}) => http.post("/admin/profileSave", data);

// 管理用户
export const userList = (data = {}) => http.post("/admin/userList", data);
export const userHandle = (data = {}) => http.post("/admin/userHandle", data);
export const userDel = (data = {}) => http.post("/admin/userDel", data);

// 访问足迹
export const visitList = (data = {}) => http.post("/admin/visitList", data);
export const visitDel = (data = {}) => http.post("/admin/visitDel", data);

// 文章及分类管理
export const articleList = (data = {}) => http.post("/admin/articleList", data);
export const articleHandle = (data = {}) => http.post("/admin/articleHandle", data);
export const articleDel = (data = {}) => http.post("/admin/articleDel", data);
export const articleVerify = (data = {}) => http.post("/admin/articleVerify", data);
export const articleCateList = (data = {}) => http.post("/admin/articleCateList", data);
export const articleCateHandle = (data = {}) => http.post("/admin/articleCateHandle", data);
export const articleCateDel = (data = {}) => http.post("/admin/articleCateDel", data);

// 产品及分类管理
export const productList = (data = {}) => http.post("/admin/productList", data);
export const productExportTpl = (data = {}) => http.post("/admin/productExportTpl", data);
export const productImport = (data = {}) => http.post("/admin/productImport", data);
export const productVerify = (data = {}) => http.post("/admin/productVerify", data);
export const productHandle = (data = {}) => http.post("/admin/productHandle", data);
export const productDel = (data = {}) => http.post("/admin/productDel", data);
export const productOneSpec = (data = {}) => http.post("/admin/productOneSpec", data);
export const productCateList = (data = {}) => http.post("/admin/productCateList", data);
export const productCateHandle = (data = {}) => http.post("/admin/productCateHandle", data);
export const productCateDel = (data = {}) => http.post("/admin/productCateDel", data);
export const specDel = (data = {}) => http.post("/admin/specDel", data); // 规格管理
export const proBigTypeCateList = (data = {}) => http.post("/admin/proBigTypeCateList", data);
// export const proTypeCateList = (data = {}) => http.post("/admin/proTypeCateList", data);
// export const proTypeCateHandle = (data = {}) => http.post("/admin/proTypeCateHandle", data);
// export const proTypeCateDel = (data = {}) => http.post("/admin/proTypeCateDel", data);
export const proParamsList = (data = {}) => http.post("/admin/proParamsList", data);
export const proParamsHandle = (data = {}) => http.post("/admin/proParamsHandle", data);
export const proParamsDel = (data = {}) => http.post("/admin/proParamsDel", data);
export const getProParams = (data = {}) => http.post("/admin/getProParams", data);

// 会员管理
export const memberList = (data = {}) => http.post("/admin/memberList", data);
export const memberHandle = (data = {}) => http.post("/admin/memberHandle", data);
export const memberDel = (data = {}) => http.post("/admin/memberDel", data);

// 询价管理
export const inquiryList = (data = {}) => http.post("/admin/inquiryList", data);
export const inquiryDel = (data = {}) => http.post("/admin/inquiryDel", data);

// 角色管理
export const roleList = (data = {}) => http.post("/admin/roleList", data);
export const roleHandle = (data = {}) => http.post("/admin/roleHandle", data);
export const roleDel = (data = {}) => http.post("/admin/roleDel", data);
export const getRuleList = (data = {}) => http.post("/admin/ruleList", data);

// 文件管理
export const filesList = (data = {}) => http.post("/admin/filesList", data);
export const filesHandle = (data = {}) => http.post("/admin/filesHandle", data);
export const filesDel = (data = {}) => http.post("/admin/filesDel", data);

// 系统管理
export const getBasic = (data = {}) => http.post("/admin/getBasic", data);
export const saveBasic = (data = {}) => http.post("/admin/saveBasic", data);

export const formList = (data = {}) => http.post("/admin/formList", data);
export const formDel = (data = {}) => http.post("/admin/formDel", data);

export const adsList = (data = {}) => http.post("/admin/adsList", data);
export const adsHandle = (data = {}) => http.post("/admin/adsHandle", data);
export const adsDel = (data = {}) => http.post("/admin/adsDel", data);
export const adsContentHandle = (data = {}) => http.post("/admin/adsContentHandle", data);

export const singlePageList = (data = {}) => http.post("/admin/singlePageList", data);
export const singlePageHandle = (data = {}) => http.post("/admin/singlePageHandle", data);
export const singlePageDel = (data = {}) => http.post("/admin/singlePageDel", data);

// 通用接口
export const uploadFile = (data = {}) => http.post("/file/upload", data);
export const editorUploadFile = (data = {}) => http.post("/file/editorUpload", data);
