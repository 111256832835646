import React, { useRef } from 'react'
import { Button, message } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { uploadFile } from "../../api"
import { nanoid } from 'nanoid';

function Upload(props) {
	const refDom = useRef(null);
	const refKey = useRef(null);
	const handleFileUpload = () => {
		console.log("ddd")
		refKey.current = nanoid()
		refDom.current.click();
	}
	const fileUploadChange = (event) => {
		console.log("event", event)
		event.preventDefault()
		const { type } = props;
		let allFiles = event.target.files
		let fileTypeFlag = false;
		Object.keys(allFiles).forEach((key) => {
			if (type === 1) {
				if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(allFiles[key].name)) {
					message.error("图片类型必须是.gif,jpeg,jpg,png,bmp中的一种");
					fileTypeFlag = true;
					return false;
				}
			}
		})
		if (fileTypeFlag) {
			return false;
		}

		let fd;
		let failure_count = 0
		Object.keys(allFiles).forEach((key) => {
			fd = new FormData()
			fd.append("file", allFiles[key]);
			fd.append("type", type);
			fd.append("key", key);
			uploadFile(fd).then(res => {
				if (res.code === 200) {
					props.refreshData();
				} else {
					message.error(res.msg)
					failure_count += 1
				}
			})
		})

		if (failure_count > 0) {
			message.error(`有 ${failure_count} 个上传失败！`)
		}
	}

	return (
		<>
			<input ref={refDom} key={refKey.current} type="file" name='fileUpload' id="fileUpload" onChange={fileUploadChange} multiple style={{ display: "none" }} />
			<Button type="primary" ghost icon={<CloudUploadOutlined />} onClick={handleFileUpload} >{props.children}</Button>
		</>
	)
}

export default Upload
