import React, { useState, useEffect } from 'react'
import { Table, Button, Form, Input, message, Modal, Pagination, Drawer } from "antd"
import { ExclamationCircleOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { singlePageList, singlePageHandle, singlePageDel } from "../../api"
import { useLangContext } from '../../utils/context'

const { confirm } = Modal;
function SinglePage(props) {
	const { langConfig } = useLangContext();
	const [drawerData, setDrawerData] = useState({})
	const [tableData, setTableData] = useState([])
	const [pageInfo, setPageInfo] = useState({ page: 1, page_size: 10, total: 0 })
	const [loading, setLoading] = useState(true)

	const [formQuery] = Form.useForm();
	const onQueryFinish = (values) => {
		if (pageInfo.page === 1) {
			getData()
		} else {
			setPageInfo({ ...pageInfo, page: 1 })
		}
	};

	// 表格模块
	const tableColumns = [
		{
			title: langConfig.s_page_name,
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: langConfig.s_page_identification,
			dataIndex: 'flag_name',
			key: 'flag_name',
		},
		{
			title: langConfig.g_create_at,
			dataIndex: 'created_at',
			key: 'created_at',
			width: 180
		},
		{
			title: langConfig.g_manage,
			key: 'Manage',
			width: '115px',
			render: (text, record) => {
				return <div>
					<Button type='link' size='small' onClick={() => drawerEditHandle(record)}>{langConfig.g_edit}</Button>
					<Button type='link' size='small' onClick={() => delData(record.id)}>{langConfig.g_del}</Button>
				</div>
			},
		},
	]

	const delData = (id) => {
		confirm({
			title: langConfig.g_reminder,
			icon: <ExclamationCircleOutlined />,
			content: langConfig.g_comfirm_del,
			cancelText: langConfig.g_cancel,
			okText: langConfig.g_ok,
			onOk() {
				singlePageDel({ id: id }).then(res => {
					getData()// message.success("数据已删除！")
					message.success(langConfig.g_del_succ)
				}).catch(err => {
					console.log(err)
				})

			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}

	const getData = () => {
		setLoading(true)
		singlePageList({ ...pageInfo, ...formQuery.getFieldsValue() }).then(res => {
			if (res.code === 200) {
				setTableData(res.data.data)
				setPageInfo({ ...pageInfo, total: res.data.total })
				setLoading(false)
			}
		}).catch(err => {
			console.log(err)
			setLoading(false)
		})
	}
	useEffect(() => {
		getData();
	}, [pageInfo.page])

	const onPageChange = (val, page_size) => {
		setPageInfo({ page_size, page: val })
	}
	const onShowSizeChange = (current, page_size) => {
		setPageInfo({ page: 1, page_size })
	}

	const [drawerConfig, setDrawerConfig] = useState({ title: '', visible: false, width: 900 });
	const drawerAddHandle = () => {
		form.resetFields();
		setDrawerData({})
		setDrawerConfig({ ...drawerConfig, title: langConfig.g_add, visible: true });
	};
	const drawerEditHandle = (row) => {
		setDrawerData(row)
		form.resetFields();
		form.setFieldsValue({ ...row })
		setDrawerConfig({ ...drawerConfig, title: langConfig.g_edit, visible: true });
	};
	const drawerCloseHandle = () => {
		setDrawerConfig({ ...drawerConfig, visible: false });
	};

	const [form] = Form.useForm();
	const onFinish = (values) => {
		singlePageHandle({ ...values, id: drawerData.id || "" }).then(res => {
			setDrawerConfig({ ...drawerConfig, visible: false })
			getData();
			message.info(res.msg)
		}).catch(err => {
			console.log(err)
		})
	};
	const layout = {
		labelCol: {
			span: 3,
		},
		wrapperCol: {
			span: 20,
		},
	};

	return (
		<div>
			<div className='breaccrumb-box'>
				<div className='breaccrumb-left'><span>{langConfig.s_title} / </span>{langConfig.s_page_title}</div>
				<div className='breaccrumb-right'><Button type="primary" size='small' onClick={() => drawerAddHandle()}> <PlusOutlined />{langConfig.g_add}</Button></div>
			</div>

			<div className='query-form-box'>
				<Form layout="inline" form={formQuery} onFinish={onQueryFinish}>
					<Form.Item name="keyword" label="">
						<Input allowClear placeholder={langConfig.s_page_name} />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit" ghost>
							<SearchOutlined />
							{langConfig.g_search}
						</Button>
					</Form.Item>
				</Form>
			</div>

			<Table size='small' rowKey='id' columns={tableColumns} dataSource={tableData} pagination={false} loading={loading} />
			<div className="page-box">
				<Pagination
					current={pageInfo.page}
					total={pageInfo.total}
					pageSize={pageInfo.page_size}
					onChange={(page, pageSize) => onPageChange(page, pageSize)}
					pageSizeOptions={[15, 30, 60, 100]}
					onShowSizeChange={(current, size) => onShowSizeChange(current, size)}
				/>
			</div>

			<Drawer title={drawerConfig.title} placement="right" maskClosable={false} width={drawerConfig.width} visible={drawerConfig.visible} onClose={drawerCloseHandle}>
				<br />
				<Form {...layout} form={form} onFinish={onFinish} >
					<Form.Item name="title" label={langConfig.s_page_name}
						rules={[
							{
								required: true,
								message: ''
							},
						]}
					>
						<Input allowClear />
					</Form.Item>
					<Form.Item name="flag_name" label={langConfig.s_page_identification}
						rules={[
							{
								required: true,
								message: ''
							},
						]}
					>
						<Input allowClear placeholder='请务必保持不同语言的相同位置的标识名称相同' />
					</Form.Item>
					<Form.Item name="content" label="HTML"
						rules={[
							{
								required: true,
								message: ''
							},
						]}
					>
						<Input.TextArea allowClear placeholder='' rows={20} />
					</Form.Item>
					<div style={{ marginTop: '10px', width: "100%", padding: "0px 0 0 110px" }}>
						<Button type="primary" htmlType="submit">{langConfig.g_save}</Button>
					</div>
				</Form>
			</Drawer>

		</div>
	)
}

export default SinglePage