import React, { useState, useEffect, useRef } from 'react'
import { Table, Button, Form, Input, message, Modal, TreeSelect } from "antd"
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import BaseModal from '../../components/modal/BaseModal'
import FileBoxModal from '../../components/fileBox/FileBoxModal'
import { articleCateList, articleCateHandle, articleCateDel } from "../../api"
import { nanoid } from 'nanoid';
import AuthBtn from '../layout/AuthBtn';
import { useLangContext } from '../../utils/context'

const { confirm } = Modal;
function ArticleCate(props) {
    const { langConfig } = useLangContext();
    const [modalConfig, setModalConfig] = useState({ visible: false, width: 920, title: '' })
    const [modalData, setModalData] = useState([])
    const [tableData, setTableData] = useState([])
    const [cateData, setCateData] = useState([])
    const [loading, setLoading] = useState(true)

    const fileboxRef = useRef(0);

    const getData = (type = "") => {
        setLoading(true)
        articleCateList({ type }).then(res => {
            if (res.code === 200) {
                if (type === 1) {
                    setTableData(res.data)
                } else {
                    setCateData([{ value: 0, title: langConfig.article_as_root }, ...res.data])
                }
                setLoading(false)
            }
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }
    useEffect(() => {
        getData(1);
        getData();
    }, [])

    // 表格模块
    const tableColumns = [
        {
            title: langConfig.g_category,
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: langConfig.g_thumb,
            dataIndex: 'thumb',
            width: '130px',
            render: (text, record) => {
                return record.file_path ? <a href={record.file_path} target="_blank" rel="noreferrer"><img src={record.file_path} alt="" style={{ width: "50px", height: "50px" }} /></a> : ''
            },
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '130px',
        },
        {
            title: langConfig.g_sort+' (desc)',
            dataIndex: 'sort',
            key: 'sort',
            width: '130px',
        },
        // {
        //     title: '外部链接',
        //     dataIndex: 'jump_url',
        //     key: 'jump_url',
        //     width: '220px',
        // },
        {
            title: langConfig.g_create_at,
            dataIndex: 'created_at',
            key: 'created_at',
            width: 180
        },
        {
            title: langConfig.g_manage,
            key: 'Manage',
            width: '115px',
            render: (text, record) => {
                return <div>
                    <AuthBtn button='admin:articlecate:save'>
                    <Button type='link' size='small' onClick={() => showEdit(record)}>{langConfig.g_edit}</Button>
                    </AuthBtn>
                    <AuthBtn button='admin:articlecate:del'>
                    <Button type='link' size='small' onClick={() => delData(record.id)}>{langConfig.g_del}</Button>
                    </AuthBtn>
                </div>
            },
        },
    ]
    const showAdd = () => {
        form.resetFields();
        setModalData([]);
        setThumbData([])
        setModalConfig({ ...modalConfig, visible: true, title: langConfig.g_add })
    }
    const showEdit = (row) => {
        form.resetFields();
        form.setFieldsValue({ ...row })
        setModalData(row);
        if (row.thumb) {
            setThumbData([row.thumb])
        } else {
            setThumbData([])
        }
        setModalConfig({ ...modalConfig, visible: true, title: langConfig.g_edit})
    }

    const delData = (id) => {
        confirm({
            title: langConfig.g_reminder,
			icon: <ExclamationCircleOutlined />,
			content: langConfig.g_comfirm_del,
			cancelText: langConfig.g_cancel,
			okText: langConfig.g_ok,
            onOk() {
                articleCateDel({ id: id }).then(res => {
                    getData(1);
                    getData();
                    // message.success("数据已删除！")
                    message.success(langConfig.g_del_succ)
                }).catch(err => {
                    console.log(err)
                })

            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    // 弹出层
    const [form] = Form.useForm();
    const onFinish = (values) => {
        let thumb_id = "";
        if (thumbData.length > 0) {
            thumb_id = thumbData[0].id;
        }
        articleCateHandle({ ...values, thumb_id, id: modalData.id || "" }).then(res => {
            setModalConfig({ ...modalConfig, visible: false })
            getData(1);
            getData();
            message.info(res.msg)
        }).catch(err => {
            console.log(err)
        })

    };
    const layout = {
        labelCol: {
            span: 5,
        },
        wrapperCol: {
            span: 19,
        },
    };
    const modalHandle = (data) => {
        setModalConfig({ ...modalConfig, visible: false })
    }

    // 文件管理配置
    const [fileBoxConfig, setFileBoxConfig] = useState({ visible: false, max_select_count: 1, width: 1000, fileboxKey: nanoid() })
    const fileBoxHanlde = (obj) => {
        if (obj.type === 'ok') {
            setThumbData(obj.data)
        }
        setFileBoxConfig({ ...fileBoxConfig, visible: false });
    }
    const showUploadThumbBox = (field) => {
        setFileBoxConfig({ ...fileBoxConfig, visible: true, fileboxKey: nanoid() });
    }
    const handleDelThumb = (obj) => {
        setThumbData(thumbData.filter((item => item !== obj)))
    }
    const [thumbData, setThumbData] = useState([])
    const uploadThumbCom = () => {
        if (thumbData.length < fileBoxConfig.max_select_count) {
            let tmp = thumbData.map((item, index) => {
                return <li key={item.id}><a href={item.file_path} target="_blank" rel="noreferrer"><img src={item.file_path} alt="" /></a><div className='action-box' onClick={() => { handleDelThumb(item) }}>{langConfig.g_del}</div></li>
            })
            return (<>{tmp}<li className='my-upload-box' onClick={() => { showUploadThumbBox() }}><PlusOutlined /></li></>)
        } else {
            return thumbData.map((item, index) => {
                return <li key={item.id}><a href={item.file_path} target="_blank" rel="noreferrer"><img src={item.file_path} alt="" /></a><div className='action-box' onClick={() => { handleDelThumb(item) }}>{langConfig.g_del}</div></li>
            })
        }
    }

    return (
        <div>
            <div className='breaccrumb-box'>
                <div className='breaccrumb-left'><span>{langConfig.article_bread_one} / </span>{langConfig.g_category} (Don't Delete)</div>
                <div className='breaccrumb-right'>
                    <AuthBtn button='admin:articlecate:save'>
                        <Button type="primary" size='small' onClick={() => showAdd()}> <PlusOutlined />{langConfig.g_add}</Button>
                    </AuthBtn>
                </div>
            </div>

            <Table size='small' rowKey='id' columns={tableColumns} dataSource={tableData} pagination={false} loading={loading} />

            <BaseModal config={modalConfig} data={modalData} handle={modalHandle}>
                <Form {...layout} form={form} onFinish={onFinish} layout="inline" className="my-inline-form">
                    <Form.Item name="title" label={langConfig.g_category}
                        rules={[
                            {
                                required: true,
                                message: ''
                            },
                        ]}
                    >
                        <Input allowClear />
                    </Form.Item>
                    <Form.Item name="parent_id" label={langConfig.g_parent}
                        rules={[
                            {
                                required: true,
                                message: ''
                            },
                        ]}
                    >
                        <TreeSelect allowClear treeData={cateData} />
                    </Form.Item>
                    <Form.Item name="seo_keyword" label={langConfig.g_keyword}>
                        <Input.TextArea allowClear />
                    </Form.Item>
                    <Form.Item name="seo_desc" label={langConfig.g_desc}>
                        <Input.TextArea allowClear />
                    </Form.Item>
                    <div style={{ height: '75px' }}></div>
                    <Form.Item name="thumb_id" label={langConfig.g_thumb}>
                        <div className='myupload-box-list'>
                            {uploadThumbCom()}
                        </div>
                    </Form.Item>
                    <Form.Item name="sort" label={langConfig.g_sort} tooltip="降序，越大越靠前">
                        <Input allowClear />
                    </Form.Item>
                    <div style={{ marginTop: '10px', textAlign: "right", width: "100%", paddingRight: "13px" }}>
                        <Button type="primary" htmlType="submit">{langConfig.g_save}</Button>
                    </div>
                </Form>
            </BaseModal>

            <FileBoxModal config={fileBoxConfig} handle={fileBoxHanlde}></FileBoxModal>

        </div>
    )
}

export default ArticleCate