import React, { useState, useEffect } from 'react'
import { Table, Button, Form, Input, message, Modal, Pagination } from "antd"
import { ExclamationCircleOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { inquiryList, inquiryDel } from "../../api"
import { nanoid } from 'nanoid';
import { useLangContext } from '../../utils/context'

const { confirm } = Modal;
function Inquiry(props) {
  const { langConfig } = useLangContext();
  const [tableData, setTableData] = useState([])
  const [pageInfo, setPageInfo] = useState({ page: 1, page_size: 10, total: 0 })
  const [loading, setLoading] = useState(true)

  const [formQuery] = Form.useForm();
  const onQueryFinish = (values) => {
    // console.log("onFinish", values);
    if (pageInfo.page === 1) {
      getData()
    } else {
      setPageInfo({ ...pageInfo, page: 1 })
    }
  };

  // 表格模块
  const tableColumns = [
    {
      title: langConfig.s_forms_company,
      dataIndex: 'name',
      key: 'name',
      width: 180,
    },
    {
      title: langConfig.g_email,
      dataIndex: 'email',
      key: 'email',
      width: 180
    },
    {
      title: langConfig.m_inquiry_content,
      dataIndex: 'content',
      key: 'content'
    },
    // {
    //   title: langConfig.s_forms_address,
    //   dataIndex: 'address',
    //   key: 'address',
    // },
    // {
    //   title: langConfig.s_forms_content,
    //   dataIndex: 'content',
    //   key: 'content',
    //   width: 220
    // },
    {
      title: langConfig.g_create_at,
      dataIndex: 'created_at',
      key: 'created_at',
      width: 110
    },
    {
      title: langConfig.g_manage,
      key: 'Manage',
      width: '75px',
      render: (text, record) => {
        return <div>
          <Button type='link' size='small' onClick={() => delData(record.id)}>{langConfig.g_del}</Button>
        </div>
      },
    },
  ]

  const delData = (id) => {
    confirm({
      title: langConfig.g_reminder,
      icon: <ExclamationCircleOutlined />,
      content: langConfig.g_comfirm_del,
      cancelText: langConfig.g_cancel,
      okText: langConfig.g_ok,
      onOk() {
        inquiryDel({ id: id }).then(res => {
          getData()
          // message.success("数据已删除！")
          message.success(langConfig.g_del_succ)
        }).catch(err => {
          console.log(err)
        })

      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const getData = () => {
    setLoading(true)
    inquiryList({ ...pageInfo, ...formQuery.getFieldsValue() }).then(res => {
      if (res.code === 200) {
        setTableData(res.data.data)
        setPageInfo({ ...pageInfo, total: res.data.total })
        setLoading(false)
      }
    }).catch(err => {
      console.log(err)
      setLoading(false)
    })
  }
  useEffect(() => {
    getData();
  }, [pageInfo.page])

  const onPageChange = (val, page_size) => {
    setPageInfo({ page_size, page: val })
  }

  const onShowSizeChange = (current, page_size) => {
    setPageInfo({ page: 1, page_size })
  }


  return (
    <div>
      <div className='breaccrumb-box'>
        <div className='breaccrumb-left'><span>{langConfig.m_bread_one} / </span>{langConfig.m_inquiry_two}</div>
        <div className='breaccrumb-right'></div>
      </div>

      <div className='query-form-box'>
        <Form layout="inline" form={formQuery} onFinish={onQueryFinish}>
          <Form.Item name="keyword" label="">
            <Input style={{ width: "290px" }} allowClear placeholder={langConfig.m_placeholder_inquiry} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" ghost>
              <SearchOutlined />
              {langConfig.g_search}
            </Button>
          </Form.Item>
        </Form>
      </div>

      {/* <Table size='small' rowKey='id' columns={tableColumns} dataSource={tableData} pagination={false} loading={loading} /> */}

      <Table  loading={loading}  size='small' rowKey='id'
        columns={tableColumns}
        expandable={{
          expandedRowRender: (record) => (
            <div
              style={{
                margin: '0 0 0 60px',
              }}
            >
              {/* 【country】：{record.country} &nbsp;&nbsp;&nbsp;&nbsp; 【address】：{record.address}  */}
              <div style={{borderBottom: '#e5e5e5 solid 1px',margin:"5px 0"}}></div>
              {
                record.inquiryitems.map(item=>{
                  return <div>【product】：<a href={item.url} target="_blank">{item.product_title}</a>&nbsp;&nbsp;&nbsp;&nbsp; 【part number】：{item.spec_part_no}</div>
                })
              }
            </div>
          ),
          rowExpandable: (record) => record.name !== 'Not Expandable',
        }}
        dataSource={tableData}
      />


      <div className="page-box">
        <Pagination
          current={pageInfo.page}
          total={pageInfo.total}
          pageSize={pageInfo.page_size}
          onChange={(page, pageSize) => onPageChange(page, pageSize)}
          pageSizeOptions={[15, 30, 60, 100]}
          onShowSizeChange={(current, size) => onShowSizeChange(current, size)}
        />
      </div>
    </div>
  )
}


export default Inquiry