import React, { useState, useEffect } from 'react'
import { Table, Button, Form, Input, message, Modal, Pagination, Drawer, Tag, Spin, Switch, Checkbox } from "antd"
import { ExclamationCircleOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { roleList, roleHandle, roleDel, getRuleList } from "../../api"
import { useLangContext } from '../../utils/context'

const { confirm } = Modal;
function Role() {
	const { langConfig } = useLangContext();
    const [currentLang, setCurrentLang] = useState(null);
	useEffect(()=>{
        setCurrentLang(localStorage.getItem("language"))
    },[])

	const [tableData, setTableData] = useState([])
	const [pageInfo, setPageInfo] = useState({ page: 1, page_size: 10, total: 10 })
	const [loading, setLoading] = useState(true)
	const [drawerLoading, setDrawerLoading] = useState(false)
	const [drawerData, setDrawerData] = useState({})
	const [ruleList, setRuleList] = useState([])

	const [formQuery] = Form.useForm();
	const onQueryFinish = (values) => {
		if (pageInfo.page === 1) {
			getData()
		} else {
			setPageInfo({ ...pageInfo, page: 1 })
		}
	};

	useEffect(() => {
		getRuleList({}).then(res => {
			if (res.code !== 200) {
				message.error(res.msg)
				return false;
			}
			setRuleList(res.data);
		}).catch(err => {
			console.log(err)
		})
	}, [])

	// 表格模块
	const tableColumns = [
		{
			title: langConfig.p_placeholder_keyword,
			dataIndex: 'title',
			key: 'title',
			render: text => text,
		},
		{
			title: langConfig.g_status,
			key: 'status',
			dataIndex: 'status',
			render: status => (
				<>
					{status === "1" ? <Tag>{langConfig.g_status_enabled}</Tag> : <Tag color="#ff4d4f">{langConfig.g_status_disabled}</Tag>}
				</>
			),
		},
		{
			title: langConfig.g_create_at,
			dataIndex: 'created_at',
			key: 'created_at',
			width: '180px'
		},
		{
			title: langConfig.g_manage,
			key: 'Manage',
			width: '115px',
			render: (text, record) => {
				return <div>
					<Button type='link' size='small' onClick={() => drawerEditHandle(record)}>{langConfig.g_edit}</Button>
					<Button type='link' size='small' onClick={() => delData(record.id)}>{langConfig.g_del}</Button>
				</div>
			},
		},
	]

	const delData = (id) => {
		confirm({
			title: langConfig.g_reminder,
			icon: <ExclamationCircleOutlined />,
			content: langConfig.g_comfirm_del,
			cancelText: langConfig.g_cancel,
			okText: langConfig.g_ok,
			onOk() {
				roleDel({ id: id }).then(res => {
					getData()
					// message.success("数据已删除！")
					message.success(langConfig.g_del_succ)
				}).catch(err => {
					console.log(err)
				})

			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}

	const getData = () => {
		setLoading(true)
		roleList({ ...pageInfo, ...formQuery.getFieldsValue() }).then(res => {
			if (res.code === 200) {
				setTableData(res.data.data)
				setPageInfo({ ...pageInfo, total: res.data.total })
				setLoading(false)
			}
		}).catch(err => {
			console.log(err)
			setLoading(false)
		})
	}
	useEffect(() => {
		getData();
	}, [pageInfo.page])

	// 分页设置
	const onPageChange = (page) => {
		setPageInfo({ ...pageInfo, page: page })
	}

	const [formDrawer] = Form.useForm();
	const [drawerConfig, setDrawerConfig] = useState({ title: '', visible: false, width: 700 });
	const drawerAddHandle = () => {
		let _form_data = {
			id: 0,
			title: '',
			status: true,
			rule_id: [],
		};
		setDrawerLoading(true)
		setDrawerData(_form_data)
		let _data = ruleList;
		let _two = [], _three = [];
		_data.forEach((item, index) => {
			_data[index].checked = false;
			if (item.children && item.children.length) {
				_two = item.children
				_two.forEach((itemtwo, indextwo) => {
					_two[indextwo].checked = false;
					if (itemtwo.children && itemtwo.children.length) {
						_three = itemtwo.children
						_three.forEach((itemthree, indexthree) => {
							_three[indexthree].checked = false;
						})
						_two[indextwo].children = _three;
					}
				})
				_data[index].children = _two;
			}
		})
		setRuleList(_data)
		setDrawerConfig({ ...drawerConfig, visible: true, title:langConfig.g_add +' '+ langConfig.p_placeholder_keyword });
		formDrawer.setFieldsValue(_form_data)
		setTimeout(() => {
			setDrawerLoading(false)
		}, 300)
	};
	const drawerEditHandle = (row) => {
		setDrawerLoading(true)
		setDrawerData(row)
		const { rule_id } = row
		let _data = ruleList;
		let _two = [], _three = [];
		//先添空原来的状态
		_data.forEach((item, index) => {
			_data[index].checked = false;
			if (item.children && item.children.length) {
				_two = item.children
				_two.forEach((itemtwo, indextwo) => {
					_two[indextwo].checked = false;
					if (itemtwo.children && itemtwo.children.length) {
						_three = itemtwo.children
						_three.forEach((itemthree, indexthree) => {
							_three[indexthree].checked = false;
						})
						_two[indextwo].children = _three;
					}
				})
				_data[index].children = _two;
			}
		})
		_data.forEach((item, index) => {
			if (rule_id.includes(String(item.id))) {
				_data[index].checked = true;
				if (item.children && item.children.length) {
					_two = item.children
					_two.forEach((itemtwo, indextwo) => {
						if (rule_id.includes(String(itemtwo.id))) {
							_two[indextwo].checked = true;
						}
						if (itemtwo.children && itemtwo.children.length) {
							_three = itemtwo.children
							_three.forEach((itemthree, indexthree) => {
								if (rule_id.includes(String(itemthree.id))) {
									_three[indexthree].checked = true;
								}
							})
							_two[indextwo].children = _three;
						}
					})
					_data[index].children = _two;
				}
			}
		})
		let _row = { ...row }
		_row.status = _row.status === "1" ? true : false
		setDrawerConfig({ ...drawerConfig, visible: true, title: langConfig.g_edit +' '+ langConfig.p_placeholder_keyword });
		formDrawer.setFieldsValue(_row)
		setRuleList(_data)
		setTimeout(() => {
			setDrawerLoading(false)
		}, 300)

	};
	const drawerCloseHandle = () => {
		setDrawerConfig({ ...drawerConfig, visible: false });
	};
	const drawerSubmitHandle = async () => {
		let checked_id = getCheckedAuthList();
		let values = await formDrawer.getFieldsValue();
		roleHandle({ ...values, id: drawerData.id,checked_id, status: values.status ? 1 : 2 }).then(res => {
			if (res.code === 200) {
				setDrawerConfig({ ...drawerConfig, visible: false })
				getData()
			}
			message.info(res.msg)
		}).catch(err => {
			setDrawerConfig({ ...drawerConfig, visible: false })
		})
	}

	// 提交前获取已选权限id
	const getCheckedAuthList = () =>{
        let checked_id = [];
        let _data = [...ruleList];
        let _two = [], _three = [];
        _data.forEach((item, index) => {
            if(item.checked){
                checked_id.push(item.id)
            }
            if (item.children && item.children.length) {
                _two = item.children
                _two.forEach((itemtwo, indextwo) => {
                    if(itemtwo.checked){
                        checked_id.push(itemtwo.id)
                    }
                    if (itemtwo.children && itemtwo.children.length) {
                        _three = itemtwo.children
                        _three.forEach((itemthree, indexthree) => {
                            if(itemthree.checked){
                                checked_id.push(itemthree.id)
                            }
                        })
                    }
                })
            }
        })
        return checked_id;
    }

	// 公用方法，设置选中状态
	const setCheckedStatus = () =>{
        let _data = [...ruleList];
        let onelen = 0,twolen = 0;
        let _two = [], _three = [];
        _data.forEach((item, index) => {
            if (item.children && item.children.length>0) {
                onelen = 0;
                _two = item.children;
                _two.forEach((itemtwo,indextwo)=>{
                    if(itemtwo.checked){
                        onelen+=1;
                    }
                    if(itemtwo.children && itemtwo.children.length > 0){
                        _three = itemtwo.children;
                        twolen = 0
                        _three.forEach((itemthree,indexthree)=>{
                            if(itemthree.checked){
                                twolen+=1;
                            }
                        })
                        // _data[index].children[indextwo].checked = twolen >0? true:false;
                    }                    
                })
                _data[index].checked = onelen > 0? true:false;
            }
        })

        _data.forEach((item, index) => {
            if (item.children && item.children.length>0) {
                onelen = 0;
                _two = item.children;
                _two.forEach((itemtwo,indextwo)=>{
                    if(itemtwo.checked){
                        onelen+=1;
                    }                   
                })
                _data[index].checked = onelen > 0? true:false;
            }
        })
        setRuleList(_data);
    }
	const handleCheckOneAuth = (key) =>{
        let _data = [...ruleList];
        let _two = [], _three = [];
        let curChecked = "";
        _data.forEach((item, index) => {
            if(index === key){
                curChecked = !item.checked;
                _data[index].checked = curChecked;
                if (item.children && item.children.length>0) {
                    _two = item.children
                    _two.forEach((itemtwo, indextwo) => {
                        _two[indextwo].checked = curChecked;
                        if (itemtwo.children && itemtwo.children.length>0) {
                            _three = itemtwo.children
                            _three.forEach((itemthree, indexthree) => {
                                _three[indexthree].checked = curChecked;
                            })
                            _two[indextwo].children = _three;
                        }
                    })
                    _data[index].children = _two;
                }
            }
        })
        setCheckedStatus(_data);
    }

    // 二级筛选
    const handleCheckTwoAuth = (firstindex,secindex) =>{
        let _data = [...ruleList];
        let _two = [], _three = [];
        let curChecked = "";
        // 循环开始
        _data.forEach((item, index) => {
            if(index === firstindex){
                // frist
                if (item.children && item.children.length>0) {
                    _two = item.children
                    // two
                    _two.forEach((itemtwo, indextwo) => {
                        if(secindex === indextwo ){
                            curChecked = !itemtwo.checked;
                            _two[indextwo].checked = curChecked;
                            if (itemtwo.children && itemtwo.children.length>0) {
                                // tree
                                _three = itemtwo.children
                                _three.forEach((itemthree, indexthree) => {
                                    _three[indexthree].checked = curChecked;
                                })
                                _two[indextwo].children = _three;
                            }
                        }
                    })
                    _data[index].children = _two;
                }
            }
        })
        setCheckedStatus(_data);
    }

    // 三级筛选
    const handleCheckThreeAuth = (firstindex,secindex,thirdindex) =>{
        let _data = [...ruleList];
        let _two = [], _three = [];
        let curChecked = "";
        _data.forEach((item, index) => {
            if(index === firstindex){
                if (item.children && item.children.length>0) {
                    _two = item.children
                    _two.forEach((itemtwo, indextwo) => {
                        if(secindex === indextwo ){
                            if (itemtwo.children && itemtwo.children.length>0) {
                                _three = itemtwo.children
                                _three.forEach((itemthree, indexthree) => {
                                    if(thirdindex === indexthree)
                                    {
                                        curChecked = !itemthree.checked;
                                        _three[indexthree].checked = curChecked;
                                    }
                                })
                                _two[indextwo].children = _three;
                            }
                        }
                    })
                    _data[index].children = _two;
                }
            }
        })
        setCheckedStatus(_data);
    }

	return (
		<div>
			<div className='breaccrumb-box'>
				<div className='breaccrumb-left'><span>{langConfig.p_bread_one} / </span>{langConfig.p_bread_two}</div>
				<div className='breaccrumb-right'>
					<Button type="primary" size='small' onClick={() => drawerAddHandle()}> <PlusOutlined />{langConfig.g_add}</Button>
				</div>
			</div>

			<div className='query-form-box'>
				<Form layout="inline" form={formQuery} onFinish={onQueryFinish}>
					<Form.Item name="keyword" label="">
						<Input allowClear placeholder={langConfig.p_placeholder_keyword} />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit" ghost>
							<SearchOutlined />
							{langConfig.g_search}
						</Button>
					</Form.Item>
				</Form>
			</div>

			<Table size='small' rowKey='id' columns={tableColumns} dataSource={tableData} pagination={false} loading={loading} />
			<div className="page-box">
				<Pagination current={pageInfo.page} total={pageInfo.total} pageSize={pageInfo.page_size} onChange={onPageChange} />
			</div>

			<Drawer title={drawerConfig.title} placement="right" maskClosable={false} width={drawerConfig.width} visible={drawerConfig.visible} onClose={drawerCloseHandle} footer={
				<Button type="primary" onClick={drawerSubmitHandle} ghost style={{ marginLeft: "88px" }} icon={<SearchOutlined />}>{langConfig.g_save}</Button>
			}>
				<Spin spinning={drawerLoading}>
					<Form form={formDrawer} labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} initialValues={drawerData}>
						<Form.Item label={langConfig.p_placeholder_keyword} name="title"><Input placeholder="" style={{ width: '330px' }} /></Form.Item>
						<Form.Item label={langConfig.g_status} name="status" valuePropName="checked">
							<Switch />
						</Form.Item>
						<Form.Item label={langConfig.p_bread_one}>
							<div>
								{
									ruleList.map((item, index) => {
										return (
											<div key={`one${index}`}>
												<div className='auth-one'>
													<Checkbox checked={item.checked} onChange={() => handleCheckOneAuth(index)}>{currentLang==='zh'?item.title:item.title_en}</Checkbox>
												</div>
												{
													item.children && item.children.length > 0 && item.children.map((itemsec, indexsec) => {
														return (
															<div key={`sec${indexsec}`} >
																<div className='auth-two'>
																	<Checkbox checked={itemsec.checked} onChange={() => handleCheckTwoAuth(index, indexsec)}>{currentLang==='zh'?itemsec.title:itemsec.title_en}</Checkbox>

																	<div className='auth-three'>
																		{
																			itemsec.children && itemsec.children.length > 0 && itemsec.children.map((itemthree, indexthree) => {
																				return <div key={`three${indexthree}`}><Checkbox checked={itemthree.checked} onChange={() => handleCheckThreeAuth(index, indexsec, indexthree)}>{currentLang==='zh'?itemthree.title:itemthree.title_en}</Checkbox>&nbsp;</div>
																			})
																		}
																	</div>
																</div>
															</div>
														)
													})
												}
											</div>
										)
									})
								}

							</div>
						</Form.Item>
						{/* <Form.Item>
							<Button type="primary" onClick={drawerSubmitHandle} ghost style={{ marginLeft: "88px" }} icon={<SearchOutlined />}>保存</Button>
						</Form.Item> */}
					</Form>
				</Spin>
			</Drawer>
		</div>
	)
}

export default Role