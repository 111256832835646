import React, { useState, useEffect } from 'react'
import { Button, Form, Input, message} from "antd"
import { PlusOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';
import FileBoxModal from '../../../components/fileBox/FileBoxModal'
import { useLangContext } from '../../../utils/context'
function AdsOne(props) {
	const { langConfig } = useLangContext();
    const {index,data,delItemhandler,submitItemHandler} = props
    const [form] = Form.useForm();
    const onFinish = (values) => {
        if(thumbData.length === 0){
            message.error("请上传图片！")
            return false;
        }
        submitItemHandler(index,{...values,thumb_id:thumbData})
    };
    useEffect(()=>{
        form.resetFields()
        form.setFieldsValue({...data})
        setThumbData(data.thumb_id)
    },[])
    const layout = {
        labelCol: {
            span: 5,
        },
        wrapperCol: {
            span: 18,
        },
    };

    const delItem = () =>{
        delItemhandler(index)
    }

    // 文件管理配置
    const [fileBoxConfig, setFileBoxConfig] = useState({ visible: false, max_select_count: 1, width: 1000, fileboxKey: nanoid() })
    const fileBoxHanlde = (obj) => {
        if (obj.type === 'ok') {
            setThumbData(obj.data)
        }
        setFileBoxConfig({ ...fileBoxConfig, visible: false });
    }
    const showUploadThumbBox = (field) => {
        setFileBoxConfig({ ...fileBoxConfig, visible: true, fileboxKey: nanoid() });
    }
    const handleDelThumb = (obj) => {
        setThumbData(thumbData.filter((item => item !== obj)))
    }
    const [thumbData, setThumbData] = useState([])
    const uploadThumbCom = () => {
        if (thumbData.length < fileBoxConfig.max_select_count) {
            let tmp = thumbData.map((item, index) => {
                return <li key={item.id}><a href={item.file_path} target="_blank" rel="noreferrer"><img src={item.file_path} alt="" /></a><div className='action-box' onClick={() => { handleDelThumb(item) }}>{langConfig.g_del}</div></li>
            })
            return (<>{tmp}<li className='my-upload-box' onClick={() => { showUploadThumbBox() }}><PlusOutlined /></li></>)
        } else {
            return thumbData.map((item, index) => {
                return <li key={item.id}><a href={item.file_path} target="_blank" rel="noreferrer"><img src={item.file_path} alt="" /></a><div className='action-box' onClick={() => { handleDelThumb(item) }}>{langConfig.g_del}</div></li>
            })
        }
    }

    return (
        <div style={{ marginBottom: '20px', height: "175px", border: "#999 dashed 1px", padding: "15px 0", position: "relative" }}>
            <Form {...layout} form={form} onFinish={onFinish} layout="inline" className="my-inline-form">
                <Form.Item name="title" label={langConfig.g_title}
                    rules={[
                        {
                            required: true,
                            message: ''
                        },
                    ]}
                >
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="url" label="URL">
                    <Input allowClear placeholder='http(s)' />
                </Form.Item>
                <Form.Item name="thumb_id" label={langConfig.g_thumb} required>
                    <div className='myupload-box-list'>
                        {uploadThumbCom()}
                    </div>
                </Form.Item>
                <Form.Item name="sort" label={langConfig.g_sort} tooltip="降序，越大越靠前">
                    <Input allowClear />
                </Form.Item>
                <div style={{ position: "absolute", right: '10px', bottom: '10px' }}>
                    <Button size='small' danger onClick={delItem}>
                        <CloseOutlined />{langConfig.g_del}
                    </Button>&nbsp;&nbsp;
                    <Button size='small' type="primary" ghost htmlType="submit">
                        <SaveOutlined />{langConfig.g_save}
                    </Button>
                </div>
            </Form>



            <FileBoxModal config={fileBoxConfig} handle={fileBoxHanlde}></FileBoxModal>
        </div>
    )
}

export default AdsOne