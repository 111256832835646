import React from 'react'

function FileVideo(props) {
    const handleItemClick = (obj) => {
        props.selectedCallBack(obj)
    }
    return (
        <div className='tab-box-img-body'>
            {
                props.data.length > 0 && props.data.map(item => {
                    return (
                        <li key={item.id} onClick={() => { handleItemClick(item) }}>
                            <div className={props.selectedItem.includes(item)?'tab-item-img-box tab-item-active':'tab-item-img-box'}>
                                <video src={item.file_path} className='tab-item-video' alt="" style={{width:"100%",height:"auto", maxHeight:"100%"}} />
                            </div>
                            <div className='tab-item-filename'>{item.original_name}</div>
                        </li>
                    )
                })
            }
        </div>
    )
}

export default FileVideo
