import React, { useState, useEffect, useRef } from 'react'
import { Table, Button, Form, Input, Select, message, Modal, Pagination, Drawer, Tag } from "antd"
import { ExclamationCircleOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import BaseModal from '../../components/modal/BaseModal'
import { adsList, adsHandle, adsDel, adsContentHandle } from "../../api"
import AdsOne from './components/AdsOne';
import { nanoid } from 'nanoid';
import { useLangContext } from '../../utils/context'

const { confirm } = Modal;
const { Option } = Select;
function Ads() {
	const { langConfig } = useLangContext();
	const drawerRef = useRef(null);
	const [modalConfig, setModalConfig] = useState({ visible: false, width: 550, title: '' })
	const [modalData, setModalData] = useState([])
	const [tableData, setTableData] = useState([])
	const [pageInfo, setPageInfo] = useState({ page: 1, page_size: 15, total: 0 })
	const [loading, setLoading] = useState(true)

	const [formQuery] = Form.useForm();
	const onQueryFinish = (values) => {
		// console.log("onFinish", values);
		if (pageInfo.page === 1) {
			getData()
		} else {
			setPageInfo({ ...pageInfo, page: 1 })
		}
	};

	// 表格模块
	const tableColumns = [
		{
			title: langConfig.s_ads_type,
			dataIndex: 'type_title',
			key: 'type_title',
			render: (text, record) => {
				return record.type === 2 ? <Tag>html</Tag> : <Tag>{langConfig.s_ads_img_text}</Tag>
			},
		},
		{
			title: langConfig.s_ads_identification,
			dataIndex: 'flag_name',
			key: 'flag_name',
		},
		{
			title: langConfig.s_ads_remarks,
			dataIndex: 'remarks',
			key: 'remarks',
		},
		{
			title: langConfig.g_create_at,
			dataIndex: 'created_at',
			key: 'created_at',
			width: 180
		},
		{
			title: langConfig.g_manage,
			key: 'Manage',
			width: '180px',
			render: (text, record) => {
				return <>
					<Button type='link' size='small' onClick={() => drawerEditHandle(record)}>{langConfig.s_ads_setting}</Button>
					<Button type='link' size='small' onClick={() => showEditModal(record)}>{langConfig.g_edit}</Button>
					<Button type='link' size='small' onClick={() => delData(record.id)}>{langConfig.g_del}</Button>
				</>
			},
		},
	]
	const showAddModal = () => {
		form.resetFields();
		setModalData([]);
		setModalConfig({ ...modalConfig, visible: true, title: langConfig.g_add })
	}
	const showEditModal = (row) => {
		form.resetFields();
		form.setFieldsValue({ ...row })
		setModalData(row);
		setModalConfig({ ...modalConfig, visible: true, title: langConfig.g_edit })
	}
	const delData = (id) => {
		confirm({
			title: langConfig.g_reminder,
			icon: <ExclamationCircleOutlined />,
			content: langConfig.g_comfirm_del,
			cancelText: langConfig.g_cancel,
			okText: langConfig.g_ok,
			onOk() {
				adsDel({ id: id }).then(res => {
					getData()
					// message.success("数据已删除！")
					message.success(langConfig.g_del_succ)
				}).catch(err => {
					console.log(err)
				})

			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}

	const getData = () => {
		setLoading(true)
		adsList({ ...pageInfo, ...formQuery.getFieldsValue() }).then(res => {
			if (res.code === 200) {
				setTableData(res.data.data)
				setPageInfo({ ...pageInfo, total: res.total })
				setLoading(false)
			}
		}).catch(err => {
			console.log(err)
			setLoading(false)
		})
	}
	useEffect(() => {
		getData();
	}, [pageInfo.page])

	// 分页设置
	const onPageChange = (val, page_size) => {
		setPageInfo({ ...pageInfo, page_size, page: val })
	}
	const onShowSizeChange = (current, page_size) => {
		setPageInfo({ ...pageInfo, page: 1, page_size })
	}

	// 弹出层
	const [form] = Form.useForm();
	const onFinish = (values) => {
		adsHandle({ ...values, id: modalData.id || "" }).then(res => {
			setModalConfig({ ...modalConfig, visible: false })
			getData();
			message.info(res.msg)
		}).catch(err => {
			console.log(err)
		})

	};
	const layout = {
		labelCol: {
			span: 5,
		},
		wrapperCol: {
			span: 18,
		},
	};
	const tailLayout = {
		wrapperCol: {
			offset: 20,
			span: 4,
		},
	};
	const modalHandle = (data) => {
		setModalConfig({ ...modalConfig, visible: false })
	}

	const [formDrawerHtml] = Form.useForm();
	const onDrawerHtmlFinish = (values) => {
		console.log(values.content);
		submitDataHandler(values.content)

	};
	// const [drawerHtmlData, setDrawerHtmlData] = useState({})
	const [drawerImgTextData, setDrawerImgTextData] = useState([])
	const [drawerConfig, setDrawerConfig] = useState({ title: '', id: '', type: '', visible: false, width: 900 });
	const drawerEditHandle = (row) => {
		drawerRef.current = nanoid();
		if (row.type === 2) {
			// setDrawerHtmlData(row)
			formDrawerHtml.resetFields();
			formDrawerHtml.setFieldsValue({ ...row })
		} else {
			if (row.content.length > 0) {
				let _drawerImgTextData = []
				row.content.forEach((item, key) => {
					_drawerImgTextData.push(item)
				})
				setDrawerImgTextData([..._drawerImgTextData])
			} else {
				setDrawerImgTextData([])
			}
		}
		setDrawerConfig({ ...drawerConfig, title: langConfig.s_ads_setting, visible: true, type: row.type, id: row.id });
	};
	const addOne = () => {
		drawerImgTextData.push({ key: nanoid(), title: '111' + nanoid(), url: '', thumb_id: [], sort: 1 })
		setDrawerImgTextData([...drawerImgTextData])
	}
	const delItemhandler = (index) => {
		let _drawerImgTextData = []
		drawerImgTextData.forEach((item, key) => {
			if (index !== key) {
				_drawerImgTextData.push(item)
			}
		})
		setDrawerImgTextData([..._drawerImgTextData])
		submitDataHandler(_drawerImgTextData)
	}
	const submitItemHandler = (index, data) => {
		let _drawerImgTextData = drawerImgTextData
		_drawerImgTextData[index] = { ...data, key: _drawerImgTextData[index].key }
		setDrawerImgTextData([..._drawerImgTextData])
		submitDataHandler(_drawerImgTextData)
	}
	const submitDataHandler = (data) => {
		adsContentHandle({ id: drawerConfig.id, type: drawerConfig.type, content: data }).then(res => {
			if (res.code === 200) {
				getData()
			}
			message.info(res.msg)
		}).catch(err => {
			console.log(err)
		})
	}
	const drawerCloseHandle = () => {
		setDrawerConfig({ ...drawerConfig, visible: false });
	};
	const layoutDrawerHtml = {
		labelCol: {
			span: 3,
		},
		wrapperCol: {
			span: 20,
		},
	};


	return (
		<div>
			<div className='breaccrumb-box'>
				<div className='breaccrumb-left'><span>{langConfig.s_title} / </span>{langConfig.s_ads_title}</div>
				<div className='breaccrumb-right'><Button type="primary" size='small' onClick={() => showAddModal()}> <PlusOutlined />{langConfig.g_add}</Button></div>
			</div>

			<div className='query-form-box'>
				<Form layout="inline" form={formQuery} onFinish={onQueryFinish}>
					<Form.Item name="keyword" label="">
						<Input allowClear placeholder={langConfig.s_ads_keyword_placeholder} />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit" ghost>
							<SearchOutlined />
							{langConfig.g_search}
						</Button>
					</Form.Item>
				</Form>
			</div>

			<Table size='small' rowKey='id' columns={tableColumns} dataSource={tableData} pagination={false} loading={loading} />
			<div className="page-box">
				<Pagination
					current={pageInfo.page}
					total={pageInfo.total}
					pageSize={pageInfo.page_size}
					onChange={(page, pageSize) => onPageChange(page, pageSize)}
					pageSizeOptions={[15, 30, 60, 100]}
					onShowSizeChange={(current, size) => onShowSizeChange(current, size)}
				/>
			</div>

			<BaseModal config={modalConfig} data={modalData} handle={modalHandle}>
				<Form {...layout} form={form} onFinish={onFinish}>
					<Form.Item name="type" label={langConfig.s_ads_type}
						rules={[
							{
								required: true,
								message: ''
							},
						]}
					>
						<Select allowClear >
							<Option value={1} >{langConfig.s_ads_img_text}</Option>
							<Option value={2} >html</Option>
						</Select>
					</Form.Item>
					<Form.Item name="flag_name" label={langConfig.s_ads_identification}
						rules={[
							{
								required: true,
								message: ''
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item name="remarks" label={langConfig.s_ads_remarks}>
						<Input.TextArea rows={4} />
					</Form.Item>
					<Form.Item {...tailLayout}>
						<Button type="primary" htmlType="submit">
							{langConfig.g_save}
						</Button>
					</Form.Item>
				</Form>
			</BaseModal>

			<Drawer key={drawerRef.current} title={drawerConfig.title} placement="right" maskClosable={false} width={drawerConfig.width} visible={drawerConfig.visible} onClose={drawerCloseHandle}>
				{
					drawerConfig.type === 2 ? <Form {...layoutDrawerHtml} form={formDrawerHtml} onFinish={onDrawerHtmlFinish} style={{ marginTop: '12px' }} >
						<Form.Item name="flag_name" label={langConfig.s_ads_identification}>
							<Input disabled />
						</Form.Item>
						<Form.Item name="content" label="HTML"
							rules={[
								{
									required: true,
									message: ''
								},
							]}
						>
							<Input.TextArea allowClear placeholder='' rows={20} />
						</Form.Item>
						<div style={{ marginTop: '10px', width: "100%", padding: "0px 0 0 110px" }}>
							<Button type="primary" htmlType="submit">{langConfig.g_save}</Button>
						</div>
					</Form> :
						<>
							{
								drawerImgTextData.length > 0 && drawerImgTextData.map((item, index) => {
									return <AdsOne key={item.key} index={index} data={item} delItemhandler={delItemhandler} submitItemHandler={submitItemHandler} ></AdsOne>
								})
							}
							<Button size='small' type="primary" ghost htmlType="submit" onClick={addOne}>
								<PlusOutlined />{langConfig.g_add}
							</Button>
						</>
				}



			</Drawer>

		</div>
	)
}

export default Ads
